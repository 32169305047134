/* eslint-disable indent */
// eslint-disable-next-line no-use-before-define
import React, { useEffect, useState } from 'react';
import classNames from 'classnames/bind';
import style from './StatusMessage.module.scss';

const cx = classNames.bind(style);

type StatusMessageProps = {
  type: string;
  message: string;
};

// eslint-disable-next-line import/prefer-default-export
export function StatusMessage(props: StatusMessageProps): JSX.Element {
  const [statusClasses, setStatusClasses] = useState({
    StatusMessage: true,
    info: true,
    success: false,
    error: false,
    warning: false,
  });

  useEffect(() => {
    switch (props.type) {
      case 'info': {
        setStatusClasses({
          ...statusClasses,
          info: true,
          success: false,
          error: false,
          warning: false,
        });
        break;
      }
      case 'success': {
        setStatusClasses({
          ...statusClasses,
          info: false,
          success: true,
          error: false,
          warning: false,
        });
        break;
      }
      case 'error': {
        setStatusClasses({
          ...statusClasses,
          info: false,
          success: false,
          error: true,
          warning: false,
        });
        break;
      }
      case 'warning': {
        setStatusClasses({
          ...statusClasses,
          info: false,
          success: false,
          error: false,
          warning: true,
        });
        break;
      }
      default: {
        setStatusClasses({
          ...statusClasses,
          info: true,
          success: false,
          error: false,
          warning: false,
        });
      }
    }
  }, [props.type]);

  if (props.message !== '') {
    return <div className={cx(statusClasses)}>{props.message}</div>;
  }

  return <React.Fragment></React.Fragment>;
}
