/* eslint-disable no-lonely-if */
import {
  takeLatest, put, call,
} from 'redux-saga/effects';
import { toast } from 'react-toastify';
import {
  fetchPatients, setPatients, setLoadPatients, sortPatients,
} from './patientListSlice';
import {
  getPatients,
} from '../../utils/apiCalls';
import { setAuthError } from '../auth/authSlice';
import { ResponseData } from '../../types/return.types';

function* fetchPatientsSaga() {
  try {
    yield put(setLoadPatients(true));
    const ninoxId = localStorage.getItem('ninoxId');
    const res: ResponseData = yield call(getPatients, Number(ninoxId));

    if (res.data) {
      if (res.error) {
        yield put(setAuthError(res.statusText));
      } else {
        const payload: any = { param: 'lastname', direction: -1 };
        yield put(setPatients(res.data));
        yield put(sortPatients(payload));
        yield put(setLoadPatients(false));
        yield put(setAuthError(null));
      }
    } else {
      console.log(res);
      // refactor the response that we received from backend
      toast.error('Etwas ist schiefgelaufen');
    }
  } catch (err) {
    // eslint-disable-next-line no-console
    console.error('New error', err);
    toast.error('Etwas ist schiefgelaufen');
  }
}

export default function* watchPatientsSaga() {
  yield takeLatest(fetchPatients.type, fetchPatientsSaga);
}
