import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import type { Preparation as PreparationType } from '../../types';
import type { RootState } from '../store';

const initialState: PreparationType[] | [] = [];

export const preparationSlice = createSlice({
  name: 'preparations',
  initialState,
  reducers: {
    setPreparations: (state, action: PayloadAction<PreparationType[]>) =>
      /* eslint-disable */
       action.payload
      /* eslint-enable */
    ,
  },
});

export const { setPreparations } = preparationSlice.actions;
export const selectPreparations = (state: RootState) => state.preparation;
