import { type ReactElement, useEffect, useState } from 'react';
import type { Participant, Room } from 'twilio-video';
import { connect } from 'twilio-video';
import { TwilioParticipant } from './TwilioParticipant';
import classes from './TwilioRoom.module.scss';

const mediaErrors = ['NotAllowedError', 'NotFoundError', 'NotReadableError', 'OverconstrainedError', 'TypeError'];

export function TwilioRoomConnecting() {
  return (
    <article className={classes['twilio-room']}>
      <p>Waiting for room to be ready...</p>
    </article>
  );
}

export function TwilioRoomError({ error }: { error: string }) {
  return (
    <article className={classes['twilio-room']}>
      <p>{error}</p>
    </article>
  );
}

/**
 * Show a Twilio video room.
 *
 * @param name Name of the room to connect to (if empty, shows "Connecting...").
 * @param token Twilio Access Token for the room to connect to (if empty, shows "Connecting...").
 */
export function TwilioRoom({ name, token }: { name: string; token: string }): ReactElement {
  const [room, setRoom] = useState<Room | undefined>(undefined);
  const [participants, setParticipants] = useState<ReadonlyArray<Participant>>([]);
  const [error, setError] = useState<string>('');

  // Connect to the Twilio API to initiate a connection to the room.
  useEffect(() => {
    let connectedRoom: Room | undefined;
    let unmounted = false;

    if (name && token) {
      const connectToRoom = async () => {
        try {
          connectedRoom = await connect(token, {
            name,
            audio: true,
            video: { facingMode: 'user' },
          });

          // If the component was unmounted before the connection was established, disconnect.
          if (unmounted) {
            connectedRoom.disconnect();

            return;
          }

          // Update participants array as they join/leave.
          connectedRoom.on('participantConnected', (participant) => {
            setParticipants((connectedParticipants) => [...connectedParticipants, participant]);
          });
          connectedRoom.on('participantDisconnected', (participant) => {
            setParticipants((connectedParticipants) => connectedParticipants.filter((x) => x.identity !== participant.identity));
          });

          // Set the current room.
          setRoom(connectedRoom);

          // Set the current participants of the room.
          setParticipants([...connectedRoom.participants.values()]);
        } catch (err) {
          if (err instanceof Error && mediaErrors.includes(err.name)) {
            setError('Unable to access camera or microphone');
          } else {
            setError('Connection error');
          }
        }
      };
      connectToRoom();
    }

    const cleanup = () => {
      unmounted = true;

      if (connectedRoom) {
        connectedRoom.disconnect();
      }
      window.removeEventListener('beforeunload', cleanup);
      window.removeEventListener('pagehide', cleanup);
      setRoom(undefined);
      setParticipants([]);
    };

    window.addEventListener('beforeunload', cleanup);
    window.addEventListener('pagehide', cleanup);

    return cleanup;
  }, [name, token]);

  if (error) {
    return <TwilioRoomError error={error} />;
  }

  if (!room) {
    return <TwilioRoomConnecting />;
  }

  return (
    <article className={classes['twilio-room']}>
      {participants.length ? (
        participants.map((x) => <TwilioParticipant key={x.identity} participant={x} />)
      ) : (
        <p>Waiting for doctor to join...</p>
      )}
      <TwilioParticipant local participant={room.localParticipant} />
    </article>
  );
}
