import {
  takeLatest, put, call,
} from 'redux-saga/effects';
import { toast } from 'react-toastify';
import {
  setLoadTasks, setTaskRequests, setTaskAppointments, fetchTasks,
} from './dashboardSlice';
import { setAuthError } from '../auth/authSlice';
import { getDoctorTasks } from '../../utils/apiCalls';
import { ResponseData } from '../../types/return.types';

function* fetchTasksSaga() {
  try {
    yield put(setLoadTasks(true));
    const res: ResponseData = yield call(getDoctorTasks);

    if (res.data) {
      if (res.error) {
        yield put(setAuthError(res.statusText));
      } else {
        const tasksResponse = res.data as any;
        yield put(setTaskRequests(tasksResponse.openRequests));
        yield put(setTaskAppointments(tasksResponse.openAppointments));

        yield put(setLoadTasks(false));
        yield put(setAuthError(null));
      }
    }
  } catch (err) {
    yield put(setAuthError(err));
    // eslint-disable-next-line no-console
    console.error('New error', err);
    toast.error('Etwas ist schiefgelaufen');
  }
}

export default function* watchTasksSaga() {
  yield takeLatest(fetchTasks.type, fetchTasksSaga);
}
