/* eslint-disable import/prefer-default-export */
import { IconDefaults } from '../IconDefaults';
import type { IconProps } from '../IconProps';

export function Doctor({ size = IconDefaults.size, color = IconDefaults.color }: IconProps) {
  return (
    <svg id='Ebene_2' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 46.706 53.002' width={size} height={size}>
      <g id='Ebene_1-2'>
        <path d='M37.175,21.195c-4.061,1.052-6.518,5.219-5.476,9.29,.695,2.682,2.794,4.781,5.477,5.475l.576,.149v2.982c0,6.21-5.052,11.263-11.262,11.263s-11.262-5.052-11.262-11.263c.222-5.267,2.611-10.253,6.558-13.713,3.803-2.648,6.034-6.911,6.034-11.458V5.526c0-3.047-2.479-5.526-5.526-5.526h-2.099c-.733,0-1.329,.596-1.329,1.329s.596,1.329,1.329,1.329h2.099c1.581,0,2.868,1.287,2.868,2.868V13.92c.006,3.002-1.16,5.826-3.281,7.952-2.124,2.128-4.951,3.3-7.96,3.3-6.209,0-11.262-5.052-11.262-11.263V5.526c0-1.582,1.287-2.868,2.869-2.868h2.098c.733,0,1.329-.596,1.329-1.329s-.596-1.329-1.329-1.329h-2.098C2.479,0,0,2.479,0,5.526V13.92c.01,4.559,2.254,8.823,6.005,11.403,4.002,3.511,6.376,8.498,6.586,13.738,.001,7.701,6.241,13.941,13.91,13.941s13.909-6.24,13.909-13.909v-2.982l.576-.149c4.071-1.054,6.528-5.221,5.477-9.289-1.053-4.07-5.221-6.529-9.288-5.477Zm1.917,12.371c-2.739,0-4.968-2.228-4.968-4.967s2.228-4.967,4.968-4.967,4.967,2.228,4.967,4.967-2.228,4.967-4.967,4.967Z' />
      </g>
    </svg>
  );
}
