/* eslint-disable import/prefer-default-export */
import { IconDefaults } from '../IconDefaults';
import type { IconProps } from '../IconProps';

export function Magnify({ size = IconDefaults.size, color = IconDefaults.color }: IconProps) {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 13 14.44' width={size} height={size}>
      <g id='a' />
      <g id='b'>
        <g id='c'>
          <g id='d'>
            <path
              fill={color}
              d='M3.29,1.64c2.04-1.98,5.23-2.19,7.45-.48,2.27,1.75,2.92,4.87,1.53,7.32-.7,1.23-1.7,2.11-3.03,2.58-1.77,.63-3.46,.41-5.05-.56-.11,.15-.21,.31-.33,.44-.85,.99-1.7,1.99-2.57,2.97-.17,.19-.4,.36-.63,.47-.41,.19-.7-.04-.66-.49,.04-.4,.23-.74,.48-1.04,.23-.28,.47-.55,.71-.83,.67-.77,1.34-1.55,2-2.32C.89,7.33,1.19,3.68,3.29,1.64Zm4.87-.57c-2.18-.38-4.3,.69-5.23,2.63-1.29,2.71,.3,5.94,3.26,6.59,2.47,.55,4.91-.81,5.68-3.16,.15-.47,.23-.95,.22-1.44,0-2.28-1.67-4.23-3.94-4.63Z'
            />
          </g>
        </g>
      </g>
    </svg>
  );
}
