/* eslint-disable no-alert */
/* eslint-disable max-len */
/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
import { useDispatch } from 'react-redux';
import React, { useEffect, useRef, useState } from 'react';
import { Button, Dropdown } from 'semantic-ui-react';
import classNames from 'classnames/bind';
// import { Dropdown } from 'react-bootstrap';
import style from './PrescriptionDetails.module.scss';
import { DoctorService } from '../../../services/doctor.service';
import { setIsDisplayPrescriptionInfo } from '../prescriptionsSlice';
import { StatusMessage } from '../../../components/atoms';

const cx = classNames.bind(style);

// eslint-disable-next-line import/prefer-default-export
export function PrescriptionDetails(props: { item: any, doctorName: string }): JSX.Element {
  const { item, doctorName } = props;
  const mediaSupport = 'mediaDevices' in navigator;
  const videoRef = useRef<HTMLVideoElement>(null);
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const mailTextRef = useRef<HTMLDivElement>(null);
  const hiddenFileInputRef = useRef<HTMLInputElement>(null);
  const [statusType, setStatusType] = useState('');
  const [statusMessage, setStatusMessage] = useState('');
  const [prescriptionImages, setPrescriptionImages] = useState<string[]>([]);
  const [videoSources, setVideoSources] = useState<MediaDeviceInfo[]>([]);
  const [selectedVideoSource, setSelectedVideoSource] = useState<MediaDeviceInfo>();
  const [videoStream, setVideoStream] = useState<MediaStream>();
  const [firstLoad, setFirstLoad] = useState(true);

  const dispatch = useDispatch();

  const setStatus = (type: string, message: string) => {
    setStatusType(type);
    setStatusMessage(message);
  };

  const getDate = (timestamp: number): string => {
    const date = new Date(timestamp);

    return `${(`0${date.getDate()}`).slice(-2)}.${(`0${date.getMonth() + 1}`).slice(-2)}.${date.getFullYear()}`;
  };

  const getTypText = (typ: number): string => {
    if (typ === 1) {
      return 'Rezept nach Termin';
    }
    if (typ === 2) {
      return 'Folgerezept';
    }
    return 'Unbekannt';
  };

  const getCameraDevices = async () => {
    const sources = await navigator.mediaDevices.enumerateDevices();
    setVideoSources(sources.filter((source) => source.kind === 'videoinput'));
    const lastSelectedVideoSource = sessionStorage.getItem('selectedVideoSource');
    const lastVideoSource = sources.find((source) => source.deviceId === lastSelectedVideoSource);
    setSelectedVideoSource(lastVideoSource || videoSources[0]);
  };

  const getStream = async () => {
    navigator.mediaDevices.getUserMedia({
      video: {
        // eslint-disable-next-line max-len
        deviceId: selectedVideoSource ? { exact: selectedVideoSource.deviceId } : undefined, width: 1500, height: 1080, facingMode: 'environment',
      },
    }).then(async (mediaStream) => {
      setVideoStream(mediaStream);

      if (videoRef.current) {
        videoRef.current.srcObject = mediaStream;

        await videoRef.current.play();
      }

      if (videoSources.length === 0) {
        getCameraDevices().then();
      }
    }).catch((error) => {
      alert(`Fehler Kamerazugriff - ${error}`);
    });
  };

  const changeVideoSource = (source: MediaDeviceInfo) => {
    if (videoStream !== undefined) {
      videoStream.getTracks().forEach((track) => {
        track.stop();
      });
    }

    sessionStorage.setItem('selectedVideoSource', source.deviceId);

    setSelectedVideoSource(source);
  };

  const takePicture = () => {
    if (mediaSupport && videoStream !== null) {
      const cropLeft = 160;
      const cropRight = 15;
      const cropTop = 60;
      const cropBottom = 55;

      canvasRef.current!.width = videoRef.current!.videoWidth - cropLeft - cropRight;
      // eslint-disable-next-line max-len
      canvasRef.current!.height = videoRef.current!.videoHeight - cropTop - cropBottom;

      const context = canvasRef.current!.getContext('2d');
      context!.filter = 'contrast(1.7) brightness(1.4)';
      // context!.drawImage(videoRef.current!, 0, 0, canvasRef.current!.width, canvasRef.current!.height);
      // eslint-disable-next-line max-len
      context!.drawImage(videoRef.current!, cropLeft, cropTop, canvasRef.current!.width, canvasRef.current!.height, 0, 0, canvasRef.current!.width, canvasRef.current!.height);

      const img = canvasRef.current!.toDataURL('image/jpeg', 1.0);
      setPrescriptionImages([...prescriptionImages, img]);
    }
  };

  const openFileSelection = () => {
    if (hiddenFileInputRef.current) {
      hiddenFileInputRef.current.click();
    }
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files.length > 0) {
      const fileUploaded = event.target.files[0];
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      // eslint-disable-next-line no-param-reassign
      event.target.value = null;
      const reader = new FileReader();
      reader.onloadend = () => {
        if (reader.result) {
          setPrescriptionImages([...prescriptionImages, reader.result.toString()]);
        }
      };
      reader.readAsDataURL(fileUploaded);
    }
  };

  const deletePicture = (index: number) => {
    const newImages = prescriptionImages;
    newImages.splice(index, 1);
    setPrescriptionImages([...newImages]);
  };

  const dataURLtoBlob = (dataURL: string) => {
    const blobBin = atob(dataURL.split(',')[1]);
    const array = [];
    for (let i = 0; i < blobBin.length; i += 1) {
      array.push(blobBin.charCodeAt(i));
    }
    return new Blob([new Uint8Array(array)], { type: 'image/jpeg' });
  };

  const sendPrescriptions = async () => {
    setStatus('info', 'Rezept wird gesendet...');
    const formData = new FormData();
    const today = new Date();
    prescriptionImages.forEach((image, index) => {
      formData.append(
        'files',
        dataURLtoBlob(image),
        // eslint-disable-next-line max-len
        `rezept-${today.getFullYear()}-${String(today.getMonth() + 1).padStart(2, '0')}-${String(today.getDate()).padStart(2, '0')}_${index}.jpeg`,
      );
    });
    formData.append('type', `${item.type}`);
    formData.append('entryId', `${item.entryId}`);
    formData.append('patientId', `${item.patientId}`);
    formData.append('message', mailTextRef.current!.innerHTML);

    const response = await DoctorService.sendPrescriptions(formData);
    if (response.error) {
      setStatus('error', 'Error - Fehler beim Senden des Rezeptes');

      return;
    }

    setStatus('success', 'Rezept erfolgreich gesendet');
    if (videoStream !== undefined) {
      videoStream.getTracks().forEach((track) => {
        track.stop();
      });
    }
    dispatch(setIsDisplayPrescriptionInfo(false));
    // navigateToPrescription();
  };

  useEffect(() => {
    if (mediaSupport && videoRef.current) {
      getCameraDevices().then(() => {
        getStream().then(() => { setFirstLoad(false); });
      });
    } else {
      alert('Kamerazugriff nicht möglich');
    }

    return () => {
      if (videoStream !== undefined) {
        videoStream.getTracks().forEach((track) => {
          track.stop();
        });
      }
    };
  }, []);

  useEffect(() => {
    if (videoSources.length > 0 && !firstLoad) {
      getStream().then();
    }
  }, [selectedVideoSource]);

  const prescriptionDetailClasses = cx({ PrescriptionDetails: true });
  const prescriptionDetailInformationClasses = cx({ PrescriptionDetailsInformation: true });
  const headingClasses = cx({ PrescriptionDetailsHeading: true });
  const subheadingClasses = cx({ PrescriptionDetailsSubHeading: true });
  const detailRowContainerClasses = cx({ PrescriptionDetailsRowContainer: true });
  const detailRowClasses = cx({ PrescriptionDetailsRow: true });
  const valueBlockClasses = cx({ PrescriptionDetailsValueBlock: true });
  const valueRowClasses = cx({ ValueRow: true });
  const valueLabelClasses = cx({ ValueLabel: true });
  const valueBoldClasses = cx({ ValueBold: true });
  const valueNoteClasses = cx({ ValueNote: true, ValueBold: true });
  const apoClasses = cx({ ValueNoApo: !item.pharmacy });

  const cameraBoxClasses = cx({ PrescriptionDetailsCameraBox: true });
  const cameraDropdownClasses = cx({ PrescriptionDetailsCameraDropdown: true });
  const cameraBoxHintClasses = cx({ PrescriptionDetailsCameraBoxHint: true });
  const cameraBoxButtonContainerClasses = cx({ PrescriptionDetailsCameraBoxButtonContainer: true });

  const prescriptionImagesHintClasses = cx({ PrescriptionDetailsImagesHint: true });
  const prescriptionImagesContainerClasses = cx({ PrescriptionDetailsImagesContainer: true });
  const prescriptionImageContainerClasses = cx({ ImageContainer: true });
  const mailHintContainerClasses = cx({ PrescriptionDetailsMailHintContainer: true });
  const mailPreviewClasses = cx({ PrescriptionDetailsMailPreview: true });

  const primaryButtonClasses = cx({ ButtonGreen: true });
  const secondaryButtonClasses = cx({ ButtonGreenSecondary: true });

  const onPrescriptionDetailPage = () => {
    dispatch(setIsDisplayPrescriptionInfo(false));
  };

  return (
    <div className={prescriptionDetailClasses}>
      <StatusMessage type={statusType} message={statusMessage} />
      <div className={detailRowClasses} style={{ alignItems: 'center' }}>
        <Button onClick={() => onPrescriptionDetailPage()} circular basic icon='arrow left' style={{ marginBottom: 9, marginRight: 10 }} />
        <div className={headingClasses}>Rezept bearbeiten</div>
      </div>

      <div>
        <div className={prescriptionDetailInformationClasses}>
          <div className={subheadingClasses}>REZEPT-INFORMATIONEN</div>
          <div className={detailRowClasses}>
            <div className={valueBlockClasses}>
              <div className={valueRowClasses}>
                <span className={valueLabelClasses}>Patient</span>
                <span className={valueBoldClasses}>{item.patient} ({String(item.patientId).padStart(5, '0')})</span>
              </div>
              <div className={valueRowClasses}>
                <span className={valueLabelClasses}>Apotheke</span>
                <span className={apoClasses}>{item.pharmacy ? item.pharmacy : 'Keine'}</span>
              </div>
              <div className={valueRowClasses}>
                <span className={valueLabelClasses}>Datum</span>
                <span>{getDate(item.date)}</span>
              </div>
            </div>
            <div className={valueBlockClasses}>
              <div className={valueRowClasses}>
                <span className={valueLabelClasses}>Arzt</span>
                <span className={valueBoldClasses}>{item.doctor}</span>
              </div>
              <div className={valueRowClasses}>
                <span className={valueLabelClasses}>Typ</span>
                <span>{getTypText(item.type)}</span>
              </div>
            </div>
            <div className={valueBlockClasses}>
              <div>Bemerkung des Arztes</div>
              <div className={valueNoteClasses}>
                {item.notes ? item.notes : '(keine)'}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className={detailRowContainerClasses}>
        <div>
          <div className={subheadingClasses}>1. REZEPT AUFNEHMEN</div>
          <div className={cameraDropdownClasses}>
            <Dropdown
              placeholder='Kamera auswählen'
              fluid
              selection
              options={ videoSources.map((device) => ({
                key: device.deviceId, value: device.deviceId, text: device.label, onClick: () => changeVideoSource(device), selected: selectedVideoSource === device,
              })) }
            />
          </div>
          <div className={cameraBoxClasses}>
            <video ref={videoRef} style={{ filter: 'contrast(1.2) brightness(1.4)' }}></video>
            <canvas ref={canvasRef} style={{ display: 'none' }}></canvas>
          </div>
          <div className={cameraBoxHintClasses}>
            Kameravorschau. Klicken Sie auf den Button um ein Rezept aufzunehmen.
          </div>
          <div className={cameraBoxButtonContainerClasses}>
            <Button onClick={takePicture} className={secondaryButtonClasses} size='small'>Rezept aufnehmen</Button>
            <Button onClick={openFileSelection} variant='outline-primary' size='small'>Rezept hochladen</Button>
            <input
              type="file"
              ref={hiddenFileInputRef}
              onChange={handleFileChange}
              style={{ display: 'none' }}
            />
          </div>
        </div>

        <div>
          <div className={subheadingClasses}>2. AUFGENOMMENE REZEPTE SPEICHERN</div>
          <div>
            {prescriptionImages.length === 0 && (
              <div className={prescriptionImagesHintClasses}>Sie haben noch kein Rezept aufgenommen</div>
            )}
            {prescriptionImages.length > 0 && (
              <div className={prescriptionImagesContainerClasses}>
                { prescriptionImages.map((img, index) => (
                  <div className={prescriptionImageContainerClasses} key={index}>
                    <img src={img} />
                    <span onClick={() => deletePicture(index)}>Löschen</span>
                  </div>
                ))}
              </div>
            )}
          </div>
          <div className={mailHintContainerClasses}>
            <p>
              Nach Abschluss der Aufnahme(n) klicken Sie bitte auf "Speichern & senden".
              Der Patient bekommt das Rezept in der App bereitgestellt
              und erhält eine E-Mail mit folgendem Text:
            </p>
          </div>
          <div className={mailPreviewClasses} ref={mailTextRef}
            suppressContentEditableWarning={true} contentEditable="true">
            <p>Hallo {item.patient},</p>
            <p>
              {/* eslint-disable-next-line max-len */}
              Ihr Rezept wurde gedruckt und an die {item.pharmacy ? item.pharmacy : (<span className={apoClasses}>Keine</span>)} auf dem Postweg versandt.
            </p>
            <p>
              Einen Scan Ihres Rezeptes finden Sie in der nowomed App unter „Meine Dokumente“.
            </p>
            <p>
              {/* eslint-disable-next-line max-len */}
              Nutzen Sie den Scan gerne, um sich als Neupatient oder bei Apothekenwechsel bei Ihrer Apotheke anzumelden. Teilweise können auch Präparate (vor-)reserviert werden. Im Kundenportal Ihrer Apotheke können Sie sich nach dem aktuellen Stand Ihrer Lieferung und den Zahlungsmodalitäten erkundigen.
            </p>
            <p>
              {/* eslint-disable-next-line max-len */}
              Bitte beachten Sie, dass es je nach Postversand und Bearbeitung ca. 2-3 Werktage dauern kann, bis das Rezept in Ihrer Wunschapotheke ankommt. Bitte haben Sie Geduld und warten mit Ihrer Kontaktaufnahme mit dem nowomed-Team. Sollte Ihr Rezept nach 7 Werktagen nicht bei Ihrer Apotheke angekommen sein, kümmern wir uns gerne um die Neu-Ausstellung Ihres Rezepts.
            </p>
            <p>
              Mit freundlichen Grüßen<br/>
              { doctorName }
            </p>
          </div>

          <div >
            <Button disabled={prescriptionImages.length === 0}
              className={primaryButtonClasses}
              onClick={sendPrescriptions} primary size='small'>Speichern & senden</Button>
          </div>
        </div>
      </div>
    </div>
  );
}
