/* eslint-disable import/prefer-default-export */
/* eslint-disable import/no-unresolved */
/* eslint-disable import/extensions */
import axios from 'axios';
import type { AxiosReturn } from '../types/return.types';
import { API_URL } from '../utils/apiUrl';

axios.defaults.baseURL = API_URL;
axios.defaults.headers.common['Content-Type'] = 'application/json';

export class PrescriptionService {
  static async createPrescription(data: unknown): Promise<AxiosReturn> {
    return new Promise((resolve) => {
      axios
        .post('/prescription', data, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('jwt')}`,
          },
        })
        .then((response) => {
          resolve({ error: false, message: 'Success', data: response.data });
        })
        .catch((error) => {
          resolve({ error: true, message: 'Error', data: error });
        });
    });
  }
}
