import axios from 'axios';
import { AxiosReturn } from '../types/return.types';
import { getAPIToken } from '../utils/apiToken';

async function getAllPractices(): Promise<AxiosReturn> {
  axios.defaults.headers.common.Authorization = `Bearer ${getAPIToken()}`;
  return new Promise((resolve) => {
    axios.get('/practice/').then((response) => {
      resolve({ error: false, message: 'Success', data: response.data });
    }).catch((error) => {
      resolve({ error: true, message: 'Error', data: error });
    });
  });
}

export default {
  getAllPractices,
};
