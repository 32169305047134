/* eslint-disable import/prefer-default-export */
import React from 'react';
import classNames from 'classnames/bind';
import {
  IconTypes, Icon, Link,
} from '../../atoms';
import style from './NavigationItem.module.scss';

const cx = classNames.bind(style);

type NavigationItemProps = {
  link?: string;
  children?: React.ReactNode;
  icon: IconTypes;
  active?: boolean;
  onClick?: () => void;
};

export function NavigationItem({
  link, children, icon, active, onClick,
}: NavigationItemProps): JSX.Element {
  const navigationItemClasses = cx({ NavigationItem: true });
  const linkClasses = cx({ NavigationItemLink: true, NavigationItemLink__active: active });

  return (
    <div className={navigationItemClasses} onClick={onClick}>
      <Icon icon={icon} size={22} color={active ? '#3db384' : '#000000'} />
      <p className={linkClasses}>
        {children}
      </p>
    </div>
  );
}
