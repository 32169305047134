/* eslint-disable @typescript-eslint/indent */
/* eslint-disable import/prefer-default-export */
import { useEffect, useState } from 'react';
import { Calendar as BigCalendar, momentLocalizer } from 'react-big-calendar';
import 'moment/locale/de';
import classNames from 'classnames/bind';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import type { AppointmentCalendar as Appointment, Event } from '../../types';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import style from './Calendar.module.scss';
import { CalendarPopup } from '../../components/molecules/CalendarPopup/CalendarPopup';
import { Loader } from '../../components/atoms';
import { availabilitySliceSelector, fetchAppointments } from '../profile/availability/availabilitySlice';
import { Patient } from '../patientOverview/Patient';
import { globalSliceSelector } from '../../app/appSlice';

const cx = classNames.bind(style);

export function Calendar(): JSX.Element {
  const CalendarClasses = cx('container', { Calendar: true });
  const CalendarContainerClasses = cx({ CalendarContainer: true });
  const localizer = momentLocalizer(moment);
  const [events, setEvents] = useState<Event[]>([]);
  const [openPopup, setOpenPopup] = useState({ id: 113, open: true });
  const dispatch = useDispatch();
  const { appointments, loadAvailability } = useSelector(availabilitySliceSelector);
  const { renderPatientOverview } = useSelector(globalSliceSelector);
  const intl = useIntl();
  const closeModal = (id: number) => {
    setOpenPopup({ id, open: false });
  };

  const PopupTermin = (event: Event) => {
    const date = moment(event.start).format('dddd, D. MMMM YYYY');
    const fromTime = moment(event.start).format('HH:mm');
    const toTime = moment(event.end).format('HH:mm');
    const duration = moment(moment(event.end).diff(moment(event.start))).format('mm');

    return (
      <CalendarPopup
        event={event}
        closeModal={closeModal}
        openPopup={openPopup}
        eventData={{
          date,
          fromTime,
          toTime,
          duration,
        }}
      />
    );
  };

  const getAppointments = async () => {
    const getAppointmentType = (appointmentType: number | undefined) => {
      switch (appointmentType) {
      case 1:
        return 'Erstgespräch';
      case 2:
        return 'Wiedervorstellung';
      case 3:
        return 'Beratung';
      default:
        return '';
      }
    };

    if (appointments.length) {
      let eventsnew = [];
      eventsnew = appointments.map((appointment: Appointment) => {
        const event = {
          start: new Date(appointment.startDate),
          end: new Date(appointment.endDate),
          patientName: appointment.patientName,
          patientId: appointment.patientId,
          kindTermin: getAppointmentType(appointment.appointmentType),
          type: appointment.type === 1 ? 'Online' : 'Offline',
        };

        return event;
      });
      setEvents(eventsnew);
    }
  };

  type EventTypeDate = {
    event: Event;
  };

  const EventItem = ({ event }: EventTypeDate) => {
    const EventClasses = cx({ CalendarEvent: true });
    const PatientNameClasses = cx({ CalendarEventPatientName: true });

    return (
      <div className={EventClasses}>
        {PopupTermin(event)}
        <div className={PatientNameClasses}>{event.patientName}</div>
        <div>{event.kindTermin}</div>
        <div>{event.type}</div>
      </div>
    );
  };

  const handleSelectedEvent = (id: number) => {
    setOpenPopup({ id, open: true });
  };

  useEffect(() => {
    if (!appointments.length) {
      const payload: any = { future: false };
      dispatch(fetchAppointments(payload));
    }
    getAppointments();
  }, [appointments.length]);

  return (
    renderPatientOverview === -1 ? (
      <div className="details_container">
        <div className={CalendarClasses}>
          {loadAvailability ? <Loader /> : (
            <div className={CalendarContainerClasses}>
              <BigCalendar
                localizer={localizer}
                events={events}
                view={'week'}
                views={['week']}
                className={style.CalendarHeight}
                scrollToTime={moment().set({ h: 7, m: 0 }).toDate()}
                components={{
                  event: EventItem,
                }}
                onSelectEvent={(event) => handleSelectedEvent(event.patientId)}
                eventPropGetter={(event) => ({
                  style: { backgroundColor: event.type === 'Online' ? '#3CB482' : '#3177C4', minHeight: 35 },
                })}
                elementProps={{
                  onScrollCapture: () => closeModal(-1),
                }}
                messages={{
                  next: intl.formatMessage({ id: 'calendar.Next' }),
                  previous: intl.formatMessage({ id: 'calendar.Previous' }),
                  today: intl.formatMessage({ id: 'calendar.Today' }),
                  month: 'Monat',
                  week: 'Woche',
                  day: 'Tag',
                  agenda: 'Tagesordnung',
                }}
              />
            </div>
          )}
        </div> </div>) : <Patient patientId={renderPatientOverview} currentPage={'Overview'}/>
  );
}
