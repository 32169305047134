/* eslint-disable @typescript-eslint/naming-convention */
import type { InputHTMLAttributes } from 'react';
import classNames from 'classnames/bind';
import style from './InputField.module.scss';

const cx = classNames.bind(style);

export type InputFieldProps = InputHTMLAttributes<HTMLInputElement>;

type inputModeType = 'search' | 'text' | 'email' | 'tel' | 'url' | 'none' | 'numeric' | 'decimal' | undefined;

export function InputField({ type, inputMode = 'text', ...props }: InputFieldProps): JSX.Element {
  const InputClasses = cx(
    {
      InputField: true,
    },
    props.className,
  );

  return (
    <input
      className={InputClasses}
      type={type}
      inputMode={inputMode as inputModeType}
      placeholder={props.placeholder}
      onChange={props.onChange}
      value={props.value}
      defaultValue={props.defaultValue}
      name={props.name}
    />
  );
}
