/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable import/prefer-default-export */
import { combineReducers, configureStore } from '@reduxjs/toolkit';
import createSagaMiddleware from 'redux-saga';
import logger from 'redux-logger';
import authReducer from '../features/auth/authSlice';
import patientsReducer from '../features/patientList/patientListSlice';
import tasksReducer from '../features/dashboard/dashboardSlice';
import availabilityReducer from '../features/profile/availability/availabilitySlice';
import patientOverviewSliceReducer from '../features/patientOverview/patientOverviewSlice';
import prescriptionsReducer from '../features/prescriptions/prescriptionsSlice';
import globalReducer from './appSlice';
import rootSaga from './saga';

// Create the saga middleware
const sagaMiddleware = createSagaMiddleware();

// Create the store middlewares array, to include saga middleware
// const middleware:  (getDefaultMiddleware: any) => getDefaultMiddleware({
//   serializableCheck: false,
//   thunk: false
// }).concat(logger);

// const middleware: (getDefaultMiddleware) => getDefaultMiddleware({
//   serializableCheck: false,
//   thunk: false
// }).concat(sagaMiddleware)
// const middleware: (getDefaultMiddleware) => [
//   ...getDefaultMiddleware({ thunk: false, serializableCheck: false }).concat(logger),
//   sagaMiddleware,
// ],
// const devMode = process.env.NODE_ENV === 'development';

// if (devMode) {
//   // If in devMode, add redux logging
//   middleware.push(logger);
// }

// Include all reducers to store
const reducer = combineReducers({
  auth: authReducer,
  global: globalReducer,
  patients: patientsReducer,
  tasks: tasksReducer,
  availability: availabilityReducer,
  patientOverview: patientOverviewSliceReducer,
  prescriptions: prescriptionsReducer,
});

export const store = configureStore({
  reducer,
  middleware: (getDefaultMiddleware) => [
    ...getDefaultMiddleware({ thunk: false, serializableCheck: false }).concat(logger),
    sagaMiddleware,
  ],
  devTools: process.env.NODE_ENV !== 'production',
  // devTools: devMode
});

// Start sagas
sagaMiddleware.run(rootSaga);
