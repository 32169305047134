/* eslint-disable import/prefer-default-export */
import type { DoctorAppointments } from '../../../types';

interface EditableAppointmentFormProps {
  appointment: DoctorAppointments;
}

const styles = {
  itemDiv: {
    display: 'flex',
    alignItems: 'center',
    fontSize: 13,
    gap: 10,
  },
  labelDiv: {
    fontWeight: 'bold',
    width: 150,
  },
};

export const ReadOnlyAppointmentForm = ({ appointment }: EditableAppointmentFormProps) => {
  const idToTodoText = (id: number | string) => {
    if (id === 1) {
      return 'Dokumente nachreichen';
    }

    return 'Patientenausweis gewünscht';
  };

  const todoText = (todos: number[] | string[]) => {
    if (todos.length > 1) {
      return `${idToTodoText(todos[0])}, ${idToTodoText(todos[1])}`;
    }

    return idToTodoText(todos[0]);
  };

  return (
    <div style={{
      display: 'flex', flexDirection: 'column', padding: 20, gap: 20,
    }}>
      <div style={styles.itemDiv}>
        <div style={styles.labelDiv}>Termin bei</div>
        <div>{appointment.doctorName}</div>
      </div>
      <div style={styles.itemDiv}>
        <div style={styles.labelDiv}>Bearbeitet von</div>
        <div>{appointment.statusSetByName ? appointment.statusSetByName : 'N/A'}</div>
      </div>
      <div style={styles.itemDiv}>
        <div style={styles.labelDiv}>Terminstatus</div>
        <div>
          {!appointment.carriedOut ? <span>N/A</span> : null}
          {appointment.carriedOut === 1 ? <span>stattgefunden</span> : null}
          {appointment.carriedOut === 2 ? <span>ausgefallen (entschuldigt)</span> : null}
          {appointment.carriedOut === 3 ? <span>ausgefallen (unentschuldigt)</span> : null}
        </div>
      </div>
      <div style={styles.itemDiv}>
        <div style={styles.labelDiv}>Rezept</div>
        <div>
          {!appointment.prescription ? <span>N/A</span> : null}
          {appointment.prescription === 1 ? <span>ausgestellt</span> : null}
          {appointment.prescription === 2 ? <span>kein Rezept</span> : null}
          {appointment.prescription === 5 ? <span>e-Rezept ausgestellt</span> : null}
        </div>
      </div>
      <div style={styles.itemDiv}>
        <div style={styles.labelDiv}>Abrechnung</div>
        <div>
          {!appointment.invoice ? <span>N/A</span> : null}
          {appointment.invoice === 1 ? <span>Nach Standard abrechnen</span> : null}
          {appointment.invoice === 2 ? <span>{`Individuelle Abrechnung: ${appointment.invoiceDetails}`}</span> : null}
        </div>
      </div>
      <div style={styles.itemDiv}>
        <div style={styles.labelDiv}>Info für Support</div>
        <div>{appointment.todos ? todoText(appointment?.todos) : 'N/A'}</div>
      </div>
      <div style={{ ...styles.itemDiv, alignItems: 'flex-start' }}>
        <div style={styles.labelDiv}>Anmerkungen</div>
        <div style={{ width: 'calc(100% - 150px)' }}>{appointment.notes ? appointment.notes : 'N/A'}</div>
      </div>
    </div>
  );
};
