/* eslint-disable import/prefer-default-export */
import { IconDefaults } from '../IconDefaults';
import type { IconProps } from '../IconProps';

export function Chevron({ size = IconDefaults.size, color = IconDefaults.color }: IconProps) {
  return (
    <svg viewBox='0 0 11.77 7.3' width={size} height={size}>
      <path d='M1,1,5.89,5.89,10.77,1' fill='none' stroke={color} strokeLinecap='round' strokeWidth='1' />
    </svg>
  );
}
