/* eslint-disable max-len */
/* eslint-disable no-lonely-if */
import {
  takeLatest, put, call,
} from 'redux-saga/effects';
import { toast } from 'react-toastify';
import {
  setLoadPatient, setPatientInfo, fetchPatientInfo, setDoctorsComments, fetchDoctorsComments, fetchDocuments, setDocuments, setPreparations, fetchPreparations, setFile, fetchPatientFile, declinePatient, setDeclinePatientInfo, setFurAppointments, fetchFurAppointments,
} from './patientOverviewSlice';
import {
  getDoctorsComments,
  getDocuments,
  getFile,
  getPatient,
  getPatientFurAppointments,
  getPreparation,
  declinePatientCall,
} from '../../utils/apiCalls';
import { setAuthError } from '../auth/authSlice';
import { ResponseData } from '../../types/return.types';

function* fetchPatientInfoSaga(action: any) {
  try {
    yield put(setLoadPatient(true));
    const res: ResponseData = yield call(getPatient, action.payload.patientId);

    if (res.data) {
      if (res.error) {
        yield put(setAuthError(res.statusText));
      } else {
        yield put(setPatientInfo(res.data));
        yield put(setLoadPatient(false));
        yield put(setAuthError(null));
      }
    } else {
      toast.error('Etwas ist schiefgelaufen');
    }
  } catch (err) {
    // eslint-disable-next-line no-console
    console.error('New error', err);
    yield put(setLoadPatient(false));
    toast.error('Etwas ist schiefgelaufen');
  }
}

function* fetchFurAppointmentsSaga(action: any) {
  try {
    yield put(setLoadPatient(true));
    const res: ResponseData = yield call(getPatientFurAppointments, action.payload.patientId);

    if (res.data) {
      if (res.error) {
        yield put(setAuthError(res.statusText));
      } else {
        yield put(setFurAppointments(res.data));
        yield put(setLoadPatient(false));
        yield put(setAuthError(null));
      }
    } else {
      toast.error('Etwas ist schiefgelaufen');
    }
  } catch (err) {
    // eslint-disable-next-line no-console
    console.error('New error', err);
    toast.error('Etwas ist schiefgelaufen');
  }
}

function* fetchDoctorCommentsSaga(action: any) {
  try {
    yield put(setLoadPatient(true));
    const res: ResponseData = yield call(getDoctorsComments, action.payload.patientId);

    if (res.data) {
      if (res.error) {
        yield put(setAuthError(res.statusText));
      } else {
        yield put(setDoctorsComments(res.data));
        yield put(setLoadPatient(false));
        yield put(setAuthError(null));
      }
    } else {
      toast.error('Etwas ist schiefgelaufen');
    }
  } catch (err) {
    // eslint-disable-next-line no-console
    toast.error('Etwas ist schiefgelaufen');
  }
}

function* fetchDocumentsSaga(action: any) {
  try {
    yield put(setLoadPatient(true));
    const res: ResponseData = yield call(getDocuments, action.payload.patientId);

    if (res.data) {
      if (res.error) {
        yield put(setAuthError(res.statusText));
      } else {
        yield put(setDocuments(res.data));
        yield put(setLoadPatient(false));
        yield put(setAuthError(null));
      }
    } else {
      toast.error('Etwas ist schiefgelaufen');
    }
  } catch (err) {
    // eslint-disable-next-line no-console
    console.error('New error', err);
    toast.error('Etwas ist schiefgelaufen');
  }
}

function* fetchPreparationsSaga() {
  try {
    yield put(setLoadPatient(true));
    const res: ResponseData = yield call(getPreparation);

    if (res.data) {
      if (res.error) {
        yield put(setAuthError(res.statusText));
      } else {
        yield put(setPreparations(res.data));
        yield put(setLoadPatient(false));
        yield put(setAuthError(null));
      }
    } else {
      toast.error('Etwas ist schiefgelaufen');
    }
  } catch (err) {
    // eslint-disable-next-line no-console
    console.error('New error', err);
    toast.error('Etwas ist schiefgelaufen');
  }
}

function* fetchPatientFileSaga(action: any) {
  try {
    yield put(setLoadPatient(true));
    const res: ResponseData = yield call(getFile, action.payload.fileId);

    if (res.data) {
      if (res.error) {
        yield put(setAuthError(res.statusText));
      } else {
        yield put(setFile(res.data));
        yield put(setLoadPatient(false));
        yield put(setAuthError(null));
      }
    } else {
      console.log(res);
    }
  } catch (err) {
    // eslint-disable-next-line no-console
    console.error('New error', err);
  }
}

function* declinePatientSaga(action: any) {
  try {
    const res: ResponseData = yield call(declinePatientCall, action.payload.patientId);

    if (res.status === 200) {
      yield put(setDeclinePatientInfo());
      toast.success('Der Patient wurde erfolgreich abgelehnt.');
    } else {
      console.log(res);
      toast.error('Etwas ist schiefgelaufen');
    }
  } catch (err) {
    // eslint-disable-next-line no-console
    console.error('New error', err);
    toast.error('Etwas ist schiefgelaufen');
  }
}

export default function* watchPatientOverviewSaga() {
  yield takeLatest(fetchPatientInfo.type, fetchPatientInfoSaga);
  yield takeLatest(fetchFurAppointments.type, fetchFurAppointmentsSaga);
  yield takeLatest(fetchDoctorsComments.type, fetchDoctorCommentsSaga);
  yield takeLatest(fetchDocuments.type, fetchDocumentsSaga);
  yield takeLatest(fetchPreparations.type, fetchPreparationsSaga);
  yield takeLatest(fetchPatientFile.type, fetchPatientFileSaga);
  yield takeLatest(declinePatient.type, declinePatientSaga);
}
