import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import classNames from 'classnames/bind';
import {
  Dimmer, List, Loader, Segment, Button,
} from 'semantic-ui-react';
import { PrescriptionTask } from '../../types/prescriptionTask.types';
import style from './Prescription.module.scss';
import { PrescriptionDetails } from './PrescriptionDetails/PrescriptionDetails';
import { DoctorService } from '../../services/doctor.service';
import { prescriptionsSliceSelector, setIsDisplayPrescriptionInfo } from './prescriptionsSlice';
import { PrescriptionsHeader } from './PrescriptionsHeader';
import { authSliceSelector } from '../auth/authSlice';

const cx = classNames.bind(style);

export default function PrescriptionsComponent() {
  // eslint-disable-next-line max-len
  const [prescriptionsOpen, setPrescriptionsOpen] = useState<Array<PrescriptionTask> | undefined>(undefined);
  // eslint-disable-next-line max-len
  const [prescriptionsClose, setPrescriptionsClose] = useState<Array<PrescriptionTask> | undefined>(undefined);
  const { isDisplayPrescriptionPage } = useSelector(prescriptionsSliceSelector);
  const { user } = useSelector(authSliceSelector);
  const [selectedTask, setSelectedTask] = useState({});

  const dispatch = useDispatch();

  const loadPrescriptions = () => {
    DoctorService.getPrescriptionTasks().then((response) => {
      const prescriptions = response.data;
      setPrescriptionsOpen(prescriptions.open);
      setPrescriptionsClose(prescriptions.closed);
    });
  };

  useEffect(() => {
    loadPrescriptions();
  }, []);

  useEffect(() => {
    if (isDisplayPrescriptionPage === false) {
      loadPrescriptions();
    }
  }, [isDisplayPrescriptionPage]);

  const getDate = (timestamp: number): string => {
    const date = new Date(timestamp);
    return `${(`0${date.getDate()}`).slice(-2)}.${(`0${date.getMonth() + 1}`).slice(-2)}.${date.getFullYear()}`;
  };

  const getTypText = (typ: number): string => {
    if (typ === 1) {
      return 'Rezept nach Termin';
    }
    if (typ === 2) {
      return 'Folgerezept';
    }
    return 'Unbekannt';
  };

  const subheadingClasses = cx({ PrescriptionSubHeading: true });

  const onPrescriptionDetailPage = (item: PrescriptionTask) => {
    dispatch(setIsDisplayPrescriptionInfo(true));
    setSelectedTask(item);
  };

  // eslint-disable-next-line max-len
  const renderPrescriptionsList = (prescriptions: Array<PrescriptionTask>, listType: string) => (!prescriptions.length
    ? (
      <Segment raised inverted className={ listType === 'open' ? 'segment-style' : 'segment-style-close' }>
        { listType === 'open' ? <FormattedMessage id='notOpenRecipes' /> : <FormattedMessage id='notClosedRecipes' /> }
      </Segment>
    )
    : prescriptions.map((item: PrescriptionTask, i:number) => (
      <Segment.Group horizontal key={i}>
        <Segment raised className={ listType === 'open' ? 'segment-style' : 'segment-style-close' }>
          <List>
            <List.Item>
              <List.Content> <FormattedMessage id='patient' />: <b> {item.patient} ({item.patientId}) </b></List.Content>
            </List.Item>
            <List.Item>
              <List.Content>Datum: {getDate(item.date)}</List.Content>
            </List.Item>
          </List>
        </Segment>
        <Segment raised className={ listType === 'open' ? 'segment-style' : 'segment-style-close' }>
          <List>
            <List.Item>
              <List.Content> <FormattedMessage id='doctorTitle' />: <b>{item.doctor}</b></List.Content>
            </List.Item>
            <List.Item>
              <List.Content> <FormattedMessage id='type' />: {getTypText(item.type)}</List.Content>
            </List.Item>
          </List>
        </Segment>
        <Segment raised className={ listType === 'open' ? 'segment-style' : 'segment-style-close' }>
          <List>
            <List.Item>
              <List.Content> <FormattedMessage id='doctorComment' /> :</List.Content>
            </List.Item>
            <List.Item>
              <List.Content>{item.notes ? <b>{item.notes}</b> : '-'}</List.Content>
            </List.Item>
          </List>
        </Segment>
        { listType === 'open'
        && <Segment raised className='segment-style'>
          <Button primary floated='right' onClick={() => onPrescriptionDetailPage(item)}><FormattedMessage id='edit' /></Button>
        </Segment> }
      </Segment.Group>
    ))
  );

  const prescriptionsListLoading = (
    <Dimmer active inverted>
      <Loader className="loading-spinner" active inline='centered'><FormattedMessage id='loading' /></Loader>
    </Dimmer>
  );

  return (
    <div className="details_container">
      {!isDisplayPrescriptionPage
        ? <div style={{ padding: '0 1rem 0 1rem' }}>
          {/* eslint-disable-next-line max-len */}
          <PrescriptionsHeader openPrescriptions={prescriptionsOpen ? prescriptionsOpen.length : 0} />
          <div className={subheadingClasses}> <FormattedMessage id='openRecipes' /></div>
          <div style={{ overflow: 'auto', height: '35rem' }}>
            { prescriptionsOpen ? renderPrescriptionsList(prescriptionsOpen, 'open') : prescriptionsListLoading }
          </div>
          <div className={subheadingClasses}><FormattedMessage id='closeRecipes' /></div>
          <div style={{ overflow: 'auto', height: '20rem' }}>
            {
              prescriptionsClose ? renderPrescriptionsList(prescriptionsClose, 'close') : prescriptionsListLoading
            }
          </div>
        </div>
        : <PrescriptionDetails item={selectedTask} doctorName={user.name} />
      }
    </div>
  );
}
