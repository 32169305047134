/* eslint-disable import/prefer-default-export */
/* eslint-disable max-len */
import classNames from 'classnames/bind';
import type { DoctorAppointments } from '../../../types';
import style from './AppointmentItems.module.scss';

const cx = classNames.bind(style);

const TextLabel = cx('TextLabel');
const TextBold = cx('TextBold');
const RowContainer = cx({ AppointmentItemsRowContainer: true });
const ColumnContainer = cx({ AppointmentItemsColumnContainer: true });

interface ClosedAppointmentHeaderProps {
  appointment: DoctorAppointments;
  appointmentType: any;
}

export const ClosedAppointmentHeader = ({ appointment, appointmentType }: ClosedAppointmentHeaderProps) => (
  <div className={RowContainer}>
    <div className={ColumnContainer}>
      <span className={TextLabel}>Datum</span>
      <span className={TextBold}>
        {new Date(appointment.startDate).toLocaleString('de-DE', {
          day: '2-digit',
          month: '2-digit',
          year: 'numeric',
        })}
      </span>
    </div>
    <div className={ColumnContainer}>
      <span className={TextLabel}>Uhrzeit</span>
      <span className={TextBold}>
        {new Date(appointment.startDate).toLocaleString('de-DE', {
          hour: '2-digit',
          minute: '2-digit',
        })}
      </span>
    </div>
    <div className={ColumnContainer}>
      <span className={TextLabel}>Typ</span>
      <span className={TextBold}>{appointmentType}</span>
    </div>
    <div className={ColumnContainer}>
      <span className={TextLabel}>Ort</span>
      {appointment.type === 1 && <span className={TextBold}>Online</span>}
      {appointment.type === 2 && <span className={TextBold}>{appointment?.practice?.name}</span>}
    </div>
    <div className={ColumnContainer}>
      <span className={TextLabel}>Arzt</span>
      <span className={TextBold}>{`${appointment?.doctorTitle} ${appointment?.doctorName}`}</span>
    </div>
    <div className={ColumnContainer}>
      <span className={TextLabel}>Terminstatus</span>
      {appointment.carriedOut === 1 ? <span className={TextBold}>hat stattgefunden</span> : null}
      {appointment.carriedOut === 2 ? <span className={TextBold}>ausgefallen (entschuldigt)</span> : null}
      {appointment.carriedOut === 3 ? <span className={TextBold}>ausgefallen (unentschuldigt)</span> : null}
    </div>
  </div>
);
