/* eslint-disable no-param-reassign */
/* eslint-disable max-len */
/* eslint-disable no-lonely-if */
import {
  takeLatest, put, call, takeEvery, select,
} from 'redux-saga/effects';
import { toast } from 'react-toastify';
import {
  setSlots, setLoadAvailability, fetchSlots, fetchAppointments, setAppointments, addSlots, availabilitySliceSelector, removeSlot,
} from './availabilitySlice';
import { ResponseData } from '../../../types/return.types';
import {
  getAppointments, deleteSlot, getDoctorSlotsPerMonth, createSlot,
} from '../../../utils/apiCalls';
import { setAuthError, logOut } from '../../auth/authSlice';
import type { Slot as SlotType } from '../../../types/slot.type';

function* fetchAppointmentsSaga(action: any) {
  try {
    yield put(setLoadAvailability(true));
    const res: ResponseData = yield call(getAppointments, action.payload.future);

    if (res.data) {
      if (res.error) {
        yield put(setAuthError(res.statusText));
      } else {
        yield put(setAppointments(res.data));
        yield put(setLoadAvailability(false));
        yield put(setAuthError(null));
      }
    } else {
      // refactor the response that we received from backend
      if (res.response.status === 403) {
        yield put(logOut());
      }
    }
  } catch (err) {
    // toast.error(err, { position: 'top-center' });
    // eslint-disable-next-line no-console
    console.error('New error', err);
  }
}

function* fetchSlotsSaga(action: any) {
  try {
    yield put(setLoadAvailability(true));
    const res: ResponseData = yield call(getDoctorSlotsPerMonth, action.payload.startDate.toString());

    if (res.data) {
      if (res.error) {
        yield put(setAuthError(res.statusText));
      } else {
        // Filter out the slot that have both slottypes of 0
        // const filteredSlots = res.data.filter(
        //  (element) => !(element.slotType === 0 && element.slotSubType === 0),
        // );

        yield put(setSlots(res.data));
        yield put(setLoadAvailability(false));
        yield put(setAuthError(null));
      }
    } else {
      // console.log(res);
    }
  } catch (err) {
    // eslint-disable-next-line no-console
    console.error('New error', err);
    toast.error('Etwas ist schiefgelaufen');
  }
}

function* addSlotsSaga(action: any) {
  try {
    yield put(setLoadAvailability(true));
    const res: ResponseData = yield call(createSlot, action.payload.slots as SlotType[]);

    if (res.data) {
      if (res.error) {
        yield put(setAuthError(res.statusText));
      } else {
        const newCreatedSlots = res.data;
        newCreatedSlots.forEach((slot) => {
          delete slot.doctor;
        });
        const { slots } = yield select(availabilitySliceSelector);

        const newarray = [...slots, ...newCreatedSlots];

        yield put(setSlots(newarray));
        yield put(setLoadAvailability(false));
        yield put(setAuthError(null));
        toast.success('Neue Slot(s) gespeichert!');
      }
    } else {
      console.log(res);
      toast.error('Etwas ist schiefgelaufen');
    }
  } catch (err) {
    // eslint-disable-next-line no-console
    console.error('New error', err);
    toast.error('Etwas ist schiefgelaufen');
  }
}

function* deleteSlotSaga(action: any) {
  try {
    yield put(setLoadAvailability(true));
    const res: ResponseData = yield call(deleteSlot, action.payload.slotToDeleteId);

    if (res.data) {
      if (res.error) {
        yield put(setAuthError(res.statusText));
      } else {
        const { slots } = yield select(availabilitySliceSelector);
        const filteredSlots = slots.filter((slot: SlotType) => slot.id !== action.payload.slotToDeleteId);

        yield put(setSlots(filteredSlots));
        yield put(setLoadAvailability(false));
        yield put(setAuthError(null));
        toast.success('Slot entfernt!');
      }
    } else {
      toast.error('Etwas ist schiefgelaufen');
    }
  } catch (err) {
    // toast.error(err, { position: 'top-center' });
    // eslint-disable-next-line no-console
    console.error('New error', err);
    toast.error('Etwas ist schiefgelaufen');
  }
}
export default function* watchAvailabilitySaga() {
  yield takeLatest(fetchAppointments.type, fetchAppointmentsSaga);
  yield takeLatest(fetchSlots.type, fetchSlotsSaga);
  yield takeEvery(addSlots, addSlotsSaga);
  yield takeEvery(removeSlot, deleteSlotSaga);
}
