/* eslint-disable @typescript-eslint/indent */
/* eslint-disable max-len */
import React from 'react';
import { AccountPlus } from './Icons/AccountPlus';
import { Calendar } from './Icons/Calendar';
import { Checked } from './Icons/Checked';
import { Checklist } from './Icons/Checklist';
import { Chevron } from './Icons/Chevron';
import { Clock } from './Icons/Clock';
import { Doctor } from './Icons/Doctor';
import { Grid } from './Icons/Grid';
import { Logo } from './Icons/Logo';
import { Logout } from './Icons/Logout';
import { Magnify } from './Icons/Magnify';
import { Trash } from './Icons/Trash';
import { IconDefaults } from './IconDefaults';

export type IconTypes =
  | 'account-plus'
  | 'calendar'
  | 'grid'
  | 'logo'
  | 'magnify'
  | 'logout'
  | 'clock'
  | 'checked'
  | 'chevron'
  | 'checklist'
  | 'trash'
  | 'doctor'
  | 'none';

export type IconProps = {
  icon: IconTypes;
  size?: number;
  color?: string;
};

export function Icon({ icon, size = IconDefaults.size, color = IconDefaults.color }: IconProps): JSX.Element {
  switch (icon) {
  case 'account-plus':
    return <AccountPlus size={size} color={color} />;
  case 'calendar':
    return <Calendar size={size} color={color} />;
  case 'grid':
    return <Grid size={size} color={color} />;
  case 'logo':
    return <Logo size={size} color={color} />;
  case 'magnify':
    return <Magnify size={size} color={color} />;
  case 'logout':
    return <Logout size={size} color={color} />;
  case 'clock':
    return <Clock size={size} color={color} />;
  case 'checked':
    return <Checked size={size} color={color} />;
  case 'chevron':
    return <Chevron size={size} color={color} />;
  case 'checklist':
    return <Checklist size={size} color={color} />;
  case 'trash':
    return <Trash size={size} color={color} />;
  case 'doctor':
    return <Doctor size={size} color={color} />;
  case 'none':
    return <></>;
  default:
    return <></>;
  }
}
