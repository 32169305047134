/* eslint-disable max-len */
/* eslint-disable import/prefer-default-export */
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import classNames from 'classnames/bind';
import { useDispatch } from 'react-redux';
import _ from 'lodash';
import type {
  FollowUpPrescriptionQuestion as FollowUpPrescriptionQuestionType,
  FollowUpPrescriptionRequest,
  PreparationListItem as PreparationListItemType,
  PreparationQuestion as PreparationQuestionType,
} from '../../../types';
import { Button } from '../../../components/atoms';
import { Dialog } from '../../../components/atoms/Dialog/Dialog';
import style from './FollowRecipe.module.scss';
import { followupPrescriptionRequest } from '../../../utils/apiCalls';
import { setChosenAvailablePrescription, setPatientInfo } from '../patientOverviewSlice';
import { Checkbox } from '../../../components/atoms/Checkbox/Checkbox';

const cx = classNames.bind(style);

type PropsTypes = {
  currentMedication: PreparationListItemType[];
  getPatient: () => void;
  patientInfo: any;
};

export function FollowRecipe({
  currentMedication,
  getPatient,
  patientInfo,
}: PropsTypes): JSX.Element {
  const pageClasses = cx({ Recipe: true });
  const GreenHeading = cx('GreenHeading');
  const TextBold = cx('TextBold');
  const TextLabel = cx('TextLabel');
  const ColumnContainer = cx('ColumnContainer');

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const followUpPrescriptionQuestion = patientInfo?.followUpPrescriptionQuestion;
  const followUpPrescriptionRequest = patientInfo?.followUpPrescriptionRequest;

  const [doctorsFreeText, setDoctorsFreeText] = useState('');
  const [showApproveDialog, setShowApproveDialog] = useState(false);
  const [showDeclineDialog, setShowDeclineDialog] = useState(false);
  const [followupRequestToShow, setFollowupRequestToShow] = useState<FollowUpPrescriptionRequest | undefined>();
  const [followupQuestionToShow, setFollowupQuestionToShow] = useState<FollowUpPrescriptionQuestionType | undefined>();
  const [preparationQuestion, setPreparationQuestion] = useState<PreparationQuestionType[] | undefined>();
  const [disabled, setDisabled] = useState(false);
  const [additionalInfo, setAdditionalInfo] = useState<string[]>([]);
  const [availableFollowUpPrescription, setAvailableFollowUpPrescription] = useState<number>(0);
  const dispatch = useDispatch();

  const additinalInfoItems = [
    { key: 'erx', label: 'e-Rezept ausgestellt' },
  ];

  const timeOfIngestionMapping = [
    { id: 1, value: 'Morgens' },
    { id: 2, value: 'Mittags' },
    { id: 3, value: 'Nachmittags' },
    { id: 4, value: 'Abends' },
    { id: 5, value: 'Zur Nacht' },
  ];

  const usedMapping = [
    { id: undefined, value: '' },
    { id: 1, value: 'Jeden Tag' },
    { id: 2, value: 'Regelmäßig (4-6 Tage die Woche)' },
    { id: 3, value: 'Gelegentlich (1–3 Tage pro Woche)' },
    { id: 4, value: 'Nur vereinzelt bei Beschwerden' },
  ];

  const trafficMapping = [
    { id: undefined, value: '' },
    { id: 1, value: 'Ja' },
    { id: 2, value: 'Nein' },
    { id: 3, value: 'Ich fahre kein Auto' },
  ];

  const closeApproveDialog = () => setShowApproveDialog(false);
  const closeDeclineDialog = () => setShowDeclineDialog(false);

  const onAdditionalInfoChange = (selectedKeys: string[]): void => {
    setAdditionalInfo(selectedKeys);
  };

  const decline = async () => {
    setDisabled(true);
    const data = {
      status: 'declined',
      doctorsMessage: doctorsFreeText,
      additionalInfo,
    };
    const declineRequest = await followupPrescriptionRequest(Number(followupRequestToShow?.id), data);

    if (declineRequest.error) {
      toast.error('Etwas ist schiefgelaufen');
    }

    if (declineRequest.data) {
      const newRequest = _.cloneDeep(followUpPrescriptionRequest);
      newRequest[newRequest.length - 1].status = 3;
      newRequest[newRequest.length - 1].doctorId = localStorage.getItem('ninoxId');

      setAvailableFollowUpPrescription(0);
      const newPatientInfo = { ...patientInfo, availableFollowUpPrescription: 0, followUpPrescriptionRequest: newRequest };
      dispatch(setPatientInfo(newPatientInfo));
      dispatch(setChosenAvailablePrescription(0));

      getPatient();
      setFollowupRequestToShow(undefined);

      toast.success('Folgerezept abgelehnt ');
    }

    closeDeclineDialog();
    setDisabled(false);
  };

  const approve = async () => {
    setDisabled(true);
    const data = {
      status: 'accepted',
      doctorsMessage: doctorsFreeText,
      additionalInfo,
    };

    const approveRequest = await followupPrescriptionRequest(Number(followupRequestToShow?.id), data);

    if (approveRequest.error) {
      toast.error('Etwas ist schiefgelaufen');
    }

    if (approveRequest.data) {
      const newRequest = _.cloneDeep(followUpPrescriptionRequest);
      newRequest[newRequest.length - 1].status = 2;
      newRequest[newRequest.length - 1].doctorId = localStorage.getItem('ninoxId');

      setAvailableFollowUpPrescription(availableFollowUpPrescription - 1);
      const newPatientInfo = { ...patientInfo, availableFollowUpPrescription: availableFollowUpPrescription - 1, followUpPrescriptionRequest: newRequest };
      dispatch(setPatientInfo(newPatientInfo));
      dispatch(setChosenAvailablePrescription(availableFollowUpPrescription - 1));

      getPatient();
      setFollowupRequestToShow(undefined);

      toast.success('Folgerezept genehmigt');
    }

    closeApproveDialog();
    setDisabled(false);
  };

  useEffect(() => {
    if (patientInfo.availableFollowUpPrescription !== undefined) {
      dispatch(setChosenAvailablePrescription(patientInfo.availableFollowUpPrescription));
      setAvailableFollowUpPrescription(patientInfo.availableFollowUpPrescription);
    }
  }, []);

  useEffect(() => {
    if (followUpPrescriptionRequest && followUpPrescriptionRequest.length > 0) {
      const lastRequest = followUpPrescriptionRequest.find((request: FollowUpPrescriptionRequest) => (request.status === undefined || request.status === 1));
      if (lastRequest !== undefined) {
        setFollowupRequestToShow(lastRequest);
        if (followUpPrescriptionQuestion.length > 0) {
          const lastquestion = followUpPrescriptionQuestion.find((question: any) => question.followUpPrescriptionRequest.find((x : number) => x === lastRequest.id));
          setFollowupQuestionToShow(lastquestion);
          setPreparationQuestion(lastquestion?.preparationQuestion);
        }
      }
    }
  }, [followUpPrescriptionRequest]);

  const generalSideEffectsLabels = (item: string, other: string | undefined) => {
    const germanText = item
      .replace('reactions', 'Allergische Reaktionen / Unverträglichkeit')
      .replace('dryMouth', 'Trockene Schleimhäute (trockener Mund / gerötete Augen)')
      .replace('appetite', 'Gesteigerter Appetit (als unangenehmer Effekt)')
      .replace('nausea', 'Übelkeit')
      .replace('increasedFatigue', 'Erhöhte Müdigkeit (als unangenehmer Effekt)')
      .replace('cardioVascularProblems', 'Herzkreislaufprobleme (Blutdruckabfall, Herzrasen, Schwindel, Unwohlsein)')
      .replace('motorLimitations', 'Motorische Einschränkungen');

    const array = germanText.split(',');
    const newArray = array.filter((element) => element !== 'sonstiges');
    if (other) {
      newArray.push(other);
    }

    const finalText = newArray.join(', ');

    return finalText;
  };

  const psychologicalSideEffectsLabels = (item: string, other: string | undefined) => {
    const germanText = item
      .replace('alteredPerception', 'Veränderte Wahrnehmung / Bewusstseinsveränderung')
      .replace('thoughtDisorders', 'Denkstörungen / Gedächtnisstörungen')
      .replace('anxiety', 'Angst und Paniksituationen')
      .replace('hallucinations', 'Halluzinationen / Psychosen')
      .replace('toleranceDevelopment', 'Starke Toleranzentwicklung');
    const array = germanText.split(',');
    const newArray = array.filter((element) => element !== 'sonstiges');
    if (other) {
      newArray.push(other);
    }
    const finalText = array.join(', ');

    return finalText;
  };

  const renderPreparationQuestions = () => (
    preparationQuestion && (preparationQuestion.map((element: PreparationQuestionType, index: number) => {
      const medicine = patientInfo.medication.find(
        (med: PreparationQuestionType) => med.id === followupQuestionToShow?.medicationId,
      );
      const used = usedMapping.find((use) => use.id === element.used);
      const timeIngestion: (string | undefined)[] = [];

      if (element.timeOfIngestion) {
        element.timeOfIngestion.map((el) => {
          const timeValue = timeOfIngestionMapping.find((tim) => tim.id === el);
          const value = timeValue?.value;

          return timeIngestion.push(value);
        });
      }

      return (
        <div key={element.id}>
          <h2>{medicine.preparationListItem[index].name}</h2>
          <div className={style.MedicineContainer}>
            <p className={TextLabel}>Maximale Menge pro Tag</p>
            <p
              className={TextBold}
            >{`${element.dailyDosageMax} ${medicine.preparationListItem[index].dosageUnit}`}</p>
          </div>
          <div className={style.MedicineContainer}>
            <p className={TextLabel}>Maximale Einzeldosis</p>
            <p
              className={TextBold}
            >{`${element.singleDosageMax} ${medicine.preparationListItem[index].dosageUnit}`}</p>
          </div>
          { /* not in use -> don't show...
          <div className={style.MedicineContainer}>
            <p className={TextLabel}>Zeitpunkt der Einnahme</p>
            <p className={TextBold}>{`${timeIngestion.join(', ')}`}</p>
          </div>
          <div className={style.MedicineContainer}>
            <p className={TextLabel}>Häufigkeit der Einnahme</p>
            <p className={TextBold}>{`${used?.value}`}</p>
          </div>
      */ }
        </div>
      );
    }))
  );

  const renderCurrentMedication = () => (
    currentMedication
                && currentMedication.map((element: PreparationListItemType) => (
                  <div className={style.RowContainer} key={element.id}>
                    <p className={style.MedicineText}>{element.name}</p>
                    {element.dosageUnit === 'g' && (
                      <div className={style.MedicineValue}>
                        <p className={TextLabel}>{`${element.quantity} ${element.dosageUnit}`}</p>
                      </div>
                    )}
                  </div>
                ))
  );

  const renderExchangePreparationsItems = () => {
    const filteredPreparationQuestion = followupQuestionToShow?.preparationQuestion?.filter((item: PreparationQuestionType) => item.changeThisPreparation);
    const foundMedication = patientInfo?.medication?.find((item: any) => followupQuestionToShow?.medicationId === item?.id);
    const itemsToRENDER = filteredPreparationQuestion?.map((item: PreparationQuestionType) => {
      const prepID = item?.preparationListItemId;
      const foundPrep = foundMedication?.preparationListItem?.find((prep: any) => prep.id === prepID);
      return (
        <p className={TextBold}>{`Für ${foundPrep?.name} ${foundPrep?.cultivar}  wünscht sich der Patient ${item?.exchangeWithThisPreparation}`}</p>
      );
    });

    return (itemsToRENDER);
  };

  return (
    <div className={pageClasses}>
      {showApproveDialog && (
        <Dialog
          message={
            'Wollen Sie den Folgerezeptantrag wirklich genehmigen? Der Folgerezeptantrag schließt sich im Anschluss und kann nicht mehr verändert werden.'
          }
          type='positiveDialog'
          spinner={'primary'}
          buttonText='Genehmigen'
          onConfirm={approve}
          onCancel={closeApproveDialog}
          disabled={disabled}
        />
      )}
      {showDeclineDialog && (
        <Dialog
          message={
            'Wollen Sie den Folgerezeptantrag wirklich ablehnen? Der Folgerezeptantrag schließt sich im Anschluss und kann nicht mehr verändert werden.'
          }
          type='negativeDialog'
          buttonText='Ablehnen'
          onConfirm={decline}
          onCancel={closeDeclineDialog}
          disabled={disabled}
        />
      )}
      <div className={style.MainContainer}>
        <div className={ColumnContainer}>
          <h1 className={GreenHeading}>ALLGEMEIN</h1>
          <div className={style.FirstContainer}>
            <div className={style.GeneraInfoContainer}>
              <p className={TextLabel}>Seit dem 03.12.2024 ist es nicht mehr notwendig, die Patienten für Folgerezepte freizugeben. Alle Patienten sind nach erfolgtem Erstgespräch automatisch freigegeben.</p>
            </div>
          </div>
          <h1 className={GreenHeading}>ANTRAG</h1>
          {followupRequestToShow ? (
            <>
              <div className={style.Container}>
                <div className={style.MedicineContainer}>
                  <p className={TextLabel}>Antrag eingereicht am</p>
                  <p className={TextBold}>
                    {followUpPrescriptionRequest
                      && new Date(
                        followUpPrescriptionRequest[followUpPrescriptionRequest.length - 1].requestDate,
                      ).toLocaleDateString('de-DE', {
                        hour: '2-digit',
                        minute: '2-digit',
                      })}
                    {' Uhr'}
                  </p>
                </div>
                <div>
                  <div className={style.MedicineContainer}>
                    <p className={TextLabel}>Allgemeine Nebenwirkungen</p>
                    <p className={TextBold}>
                      {followupQuestionToShow?.generalSideEffects
                        && generalSideEffectsLabels(
                          followupQuestionToShow.generalSideEffects,
                          followupQuestionToShow.generalSideEffectsOther,
                        )}
                    </p>
                  </div>
                  <div className={style.MedicineContainer}>
                    <p className={TextLabel}>Psychische Nebenwirkungen</p>
                    <p className={TextBold}>
                      {followupQuestionToShow?.psychologicalSideEffects
                        && psychologicalSideEffectsLabels(
                          followupQuestionToShow.psychologicalSideEffects,
                          followupQuestionToShow.psychologicalSideEffectsOther,
                        )}
                    </p>
                  </div>
                  <div className={style.MedicineContainer}>
                    <p className={TextLabel}>Sicher in der Anwendung?</p>
                    <p className={TextBold}>
                      {followupQuestionToShow?.isSafeHandling === true && 'Ja'}
                      {followupQuestionToShow?.isSafeHandling === false && 'Nein'}
                    </p>
                  </div>
                  <div className={style.MedicineContainer}>
                    <p className={TextLabel}>Fähig am Straßenverkehr teilzunehmen?</p>
                    <p className={TextBold}>
                      {trafficMapping.find((traf) => traf.id === followupQuestionToShow?.roadTraffic)?.value}
                    </p>
                  </div>
                </div>
              </div>
              <div className={style.WhiteBoxContainer}>
                <div className={style.MedicineContainer}>
                  <p className={style.BlackHeadings}>Neue Medikamente</p>
                  <p className={TextBold}>{followupQuestionToShow?.newMedicines || 'Nein'}</p>
                </div>
                <div className={style.MedicineContainer}>
                  <p className={style.BlackHeadings}>Medizinische Neuigkeiten</p>
                  <p className={TextBold}>{followupQuestionToShow?.medicalNews || 'Nein'}</p>
                </div>
                <div className={style.MedicineContainer}>
                  <p className={TextLabel}>Apotheke</p>
                  <p className={TextBold}>{patientInfo?.patientPharmacyRelation?.name}</p>
                </div>
                <div>
                  <h2 className={style.BlackHeadings}>Austauschpräparate</h2>
                  {followupQuestionToShow?.medicationAvailable === 1 && (
                    <p className={TextBold}>Lieferbarkeit ist überprüft, keine Austauschpräparate gewünscht.</p>
                  )}
                  {followupQuestionToShow?.medicationAvailable === 2 && (
                    <p className={TextBold}>Lieferbarkeit ist <b>nicht</b> überprüft, keine Austauschpräparate gewünscht.</p>
                  )}
                  {followupQuestionToShow?.medicationAvailable === 3 && (
                    renderExchangePreparationsItems()
                  )}
                </div>
                <div>
                  <p className={TextLabel}>
                    Ihre Nachricht an den / die Patient*in (Die Nachricht wird Teil einer automatisierten E-Mail. Füllen
                    Sie diese bitte nur bei Genehmigung aus. Eine Anrede und Verabschiedung werden ebenfalls
                    automatisiert hinzugefügt).
                  </p>
                  <textarea
                    className={style.InputFreeText}
                    value={doctorsFreeText}
                    onChange={(event) => {
                      setDoctorsFreeText(event.target.value);
                    }}
                  ></textarea>
                </div>
                <div>
                  <div className={style.GreyText}>
                    <p>Genehmigen Sie den Antrag erst, nachdem die Verschreibung erstellt wurde.</p>
                  </div>
                  <div className={style.ButtonsContainer}>
                    <Checkbox
                      value={additionalInfo}
                      name='supportInfo'
                      items={additinalInfoItems}
                      layout='horizontal'
                      onChange={onAdditionalInfoChange}
                    />
                    <span style={{ width: '2rem' }}></span>
                    <Button
                      children={'Antrag ablehnen'}
                      className={style.RedButton}
                      type={'secondary'}
                      onClick={() => setShowDeclineDialog(true)}
                    />
                    <Button
                      children={'Antrag genehmigen'}
                      className={style.GreenButton}
                      type={'primary'}
                      onClick={() => setShowApproveDialog(true)}
                    />
                  </div>
                </div>
              </div>
            </>
          ) : (
            <div className={style.Container}>
              <p className={TextLabel}>Es liegt kein Antrag vor.</p>
            </div>
          )}
        </div>
        {currentMedication.length !== 0 && (
          <div>
            <h1 className={GreenHeading}>AKTUELLE MEDIKATION</h1>
            <div className={style.MedicationContainer}>
              {renderCurrentMedication()}
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
