/* eslint-disable import/prefer-default-export */
import React from 'react';
import classNames from 'classnames/bind';
import { FormattedMessage } from 'react-intl';
import { Icon } from '../../atoms';
import style from './ProfileNavigation.module.scss';

const cx = classNames.bind(style);

type ProfileNavProps = {
  currentPage: string;
  setCurrentPage: React.Dispatch<React.SetStateAction<string>>;
};

export function ProfileNavigation({ currentPage, setCurrentPage }: ProfileNavProps): JSX.Element {
  const containerClasses = cx({ PatientNavigationContainer: true });
  const RowContainer = cx({ PatientNavigationRowContainer: true });
  const MainContainer = cx({ PatientNavigationMainContainer: true });
  const NavItemsContainer = cx({ PatientNavigationNavItemsContainer: true });
  const NameItemsContainers = cx({ PatientNavigationNameItemsContainers: true });
  const NameHeading = cx({ PatientNavigationNameHeading: true });
  const NavItems = cx({ PatientNavigationItems: true });
  const NavSelectedItem = cx({ PatientNavigationSelectedItem: true });

  const onClick = (route: string) => {
    setCurrentPage(route);
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
  };

  return (
    <div className={containerClasses}>
      <div className={RowContainer}>
        <Icon icon={'doctor'} size={60} />
        <div className={MainContainer}>
          <div className={NameItemsContainers}>
            <p className={NameHeading}> <FormattedMessage id='profile.Title' /></p>
          </div>
          <div className={NavItemsContainer}>
            <p
              className={currentPage === 'Overview' ? NavSelectedItem : NavItems}
              onClick={() => {
                onClick('Overview');
              }}
            >
              <FormattedMessage id='profile.Overview' />
            </p>
            <p
              className={currentPage === 'Availability' ? NavSelectedItem : NavItems}
              onClick={() => {
                onClick('Availability');
              }}
            >
              <FormattedMessage id='profile.Availability' />
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
