/* eslint-disable import/prefer-default-export */
import React, { useState } from 'react';
import 'moment/locale/de';
import classNames from 'classnames/bind';
import style from './Profile.module.scss';
import { Availability } from './availability/Availability';
import { ProfileNavigation } from '../../components/organisms/ProfileNavigation/ProfileNavigation';

const cx = classNames.bind(style);
const pageClasses = cx('container');

export function Profile(): JSX.Element {
  const [currentPage, setCurrentPage] = useState('Overview');

  return (
    <div className="details_container">
      <div className={pageClasses}>
        <div >
          <ProfileNavigation currentPage={currentPage} setCurrentPage={setCurrentPage} />
          { currentPage === 'Overview'
            ? <div style={{ marginLeft: '30px' }}>
              <h1>Overview of Profile</h1>
            </div>
            : <Availability />}
        </div>
      </div>
    </div>
  );
}
