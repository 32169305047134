/* eslint-disable import/prefer-default-export */
import { IconDefaults } from '../IconDefaults';
import type { IconProps } from '../IconProps';

export function Calendar({ size = IconDefaults.size, color = IconDefaults.color }: IconProps) {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 17 18.47' width={size} height={size}>
      <g id='a' />
      <g id='b'>
        <g id='c'>
          <path
            fill={color}
            d='M14.78,18.47H2.22c-1.23,0-2.22-1-2.22-2.22V3.69C0,2.47,1,1.47,2.22,1.47H14.78c1.23,0,2.22,1,2.22,2.22v12.55c0,1.23-1,2.22-2.22,2.22ZM2.22,1.97c-.95,0-1.72,.77-1.72,1.72v12.55c0,.95,.77,1.72,1.72,1.72H14.78c.95,0,1.72-.77,1.72-1.72V3.69c0-.95-.77-1.72-1.72-1.72H2.22Z'
          />
          <rect fill={color} x='.28' y='4.37' width='16.37' height='.5' />
          <path
            fill={color}
            d='M3.91,3.58c-.21,0-.38-.17-.38-.38V.38c0-.21,.17-.38,.38-.38s.38,.17,.38,.38V3.21c0,.21-.17,.38-.38,.38Z'
          />
          <path
            fill={color}
            d='M12.71,3.69c-.21,0-.38-.17-.38-.38V.48c0-.21,.17-.38,.38-.38s.38,.17,.38,.38V3.31c0,.21-.17,.38-.38,.38Z'
          />
        </g>
      </g>
    </svg>
  );
}
