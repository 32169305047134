const { REACT_APP_API_URL } = process.env;

export const API_URL = REACT_APP_API_URL;

// auth
export const LOGIN_USER = `${API_URL}/user/authenticate`;
export const FORGOT_PASSWORD = `${API_URL}/user/forgot-password`;
export const RESET_PASSWORD = `${API_URL}/user/reset-password`;
// Doctor
export const CREATE_DOCTOR = `${API_URL}/doctor`;
export const GET_DOCTOR_COMMENTS = (patientId: number) => `${API_URL}/patient/${patientId}/remark`;
export const GET_ALL_DOCTORS = `${API_URL}/doctor`;
export const GET_ALL_DOCTORS_APPOINTMENTS_STATS = `${API_URL}/doctor/appointment-stats`;
export const GET_DOCTOR = `${API_URL}/doctor/`;
export const GET_APPOINTMENT_STATS = `${API_URL}/doctor/appointment-stats`;
export const GET_FREE_APPOINTMENTS = (doctorID: number) => `${API_URL}/doctor/${doctorID}/free-appointments/all`;
export const GET_DOCTOR_ABSENCES = (doctorID: number) => `${API_URL}/doctor/${doctorID}/absence`;
export const DELETE_DOCTOR_ABSENCE = `${API_URL}/doctor/absence`;
export const UPDATE_DOCTOR_ABSENCE = `${API_URL}/doctor/absence`;
export const CREATE_DOCTOR_ABSENCES = (doctorID: number) => `${API_URL}/doctor/${doctorID}/absence`;
export const GET_DOCTOR_TASKS = `${API_URL}/doctor/tasks`;
export const GET_DOCTOR_TASK_REQUEST = `${API_URL}/doctor/tasks-requests`;
export const GET_DOCTOR_TASK_APPOINTMENTS = `${API_URL}/doctor/tasks-appointments`;
export const GET_DOCTOR_DATA = `${API_URL}/doctor/tasks-requests`;
export const GET_DOCTOR_SLOTS = `${API_URL}/doctor/slot`;
export const CREATE_DOCTOR_SLOT = `${API_URL}/doctor/slot`;
export const UPDATE_DOCTOR_SLOT = `${API_URL}/doctor/slot/`;
export const DELETE_DOCTOR_SLOT = `${API_URL}/doctor/slot/`;
export const GET_DOCTOR_SLOTS_PER_MONTH = (startDate: string) => `${API_URL}/doctor/slot/${startDate}`;

// Patients
export const FETCH_PATIENTS = `${API_URL}/patient/doctor/`;
export const GET_PATIENT = `${API_URL}/patient/`;
export const GET_PATIENT_FUR_APPOINTMENTS = (patientId: number) => `${API_URL}/appointment/fur/patient/${patientId}`;
export const GET_PATIENT_DOCUMENTS = (patientId: number) => `${API_URL}/patient/${patientId}/file`;
export const AVAILABLE_FOLLOW_UP_PRESCRIPTION = (patientId: number) => `${API_URL}/patient/${patientId}/available-followup-prescription`;
export const DECLINE_PATIENT = (patientId: number) => `${API_URL}/doctor/${patientId}/decline-patient`;
// Appointments
export const FETCH_APPOINTMENTS = `${API_URL}/appointment`;
export const GET_APPOINTMENTS = `${API_URL}/appointment?future=`;
export const SET_APPOINTMENT_STATUS = `${API_URL}/appointment/`;
// Practices
export const FETCH_PRACTICES = `${API_URL}/practice/free-appointments-stats`;
export const GET_FREE_APPOINTMENTS_PRACTICE = (practiceId: number) => `${API_URL}/practice/${practiceId}/free-appointments`;
export const GET_FREE_APPOINTMENTS_STATS = `${API_URL}/practice/free-appointments-stats`;
// Documents
export const GET_DOCUMENT_FILED = `${API_URL}/document/`;
// Preparation
export const GET_PREPARATION = `${API_URL}/preparation`;
// Medication
export const FETCH_MEDICATION = `${API_URL}/medication`;
// Prescriptions
export const FOLLOW_UP_PRESCRIPTION_REQUEST = (requestId: number) => `${API_URL}/followup-prescription-request/${requestId}/status`;

export const FETCH_PRESCRIPTIONS = `${API_URL}/doctor/prescription-tasks`;
