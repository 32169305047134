/* eslint-disable import/prefer-default-export */
import React, { useEffect } from 'react';
import classNames from 'classnames/bind';
import style from './InputLoader.module.scss';

const cx = classNames.bind(style);

type InputLoaderProps = { className?: string; spinner: 'none' | 'primary' | 'secondary' | 'red-primary' };

export function InputLoader({ spinner = 'none', ...props }: InputLoaderProps): JSX.Element {
  const LinkClasses = cx(
    {
      InputLoader: true,
      [`InputLoader-${spinner}`]: true,
    },
    props.className,
  );

  return <div className={LinkClasses}></div>;
}
