import { type ReactElement, useEffect, useState } from 'react';
import type { AudioTrackPublication, Participant, VideoTrackPublication } from 'twilio-video';
import { TwilioAudioTrack } from './TwilioAudioTrack';
import { TwilioVideoTrack } from './TwilioVideoTrack';
import classes from './TwilioRoom.module.scss';

/**
 * Identity must be unique which causes some issues so we use the connection time as a string to allow multiple identities.
 * This trims the "#9173839302" off the end of an identity string.
 */
function cleanIdentity(identity: string) {
  return identity.split(' #')[0];
}

export function TwilioParticipant({
  local = false,
  participant,
}: {
  local?: boolean;
  participant: Participant;
}): ReactElement {
  const [videoTracks, setVideoTracks] = useState<ReadonlyArray<VideoTrackPublication>>(
    Array.from(participant.videoTracks.values()),
  );
  const [audioTracks, setAudioTracks] = useState<ReadonlyArray<AudioTrackPublication>>(
    Array.from(participant.audioTracks.values()),
  );

  useEffect(() => {
    if (!local) {
      participant.on('trackSubscribed', (track) => {
        if (track.kind === 'video') {
          setVideoTracks((tracks) => [...tracks, track]);
        }
        if (track.kind === 'audio') {
          setAudioTracks((tracks) => [...tracks, track]);
        }
      });
    }
  });

  return (
    <figure
      className={`${classes['twilio-participant']} ${classes[local ? 'twilio-participant-local' : 'twilio-participant-remote']}`}
    >
      {videoTracks.map((t) => (t.track ? <TwilioVideoTrack key={t.trackSid} track={t.track} local={local} /> : null))}
      {audioTracks.map((t) => (t.track ? <TwilioAudioTrack key={t.trackSid} track={t.track} local={local} /> : null))}
      <figcaption>{cleanIdentity(participant.identity)}</figcaption>
    </figure>
  );
}
