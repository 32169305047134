/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable no-param-reassign */
/* eslint-disable array-callback-return */
/* eslint-disable consistent-return */
/* eslint-disable camelcase */
import { createSlice } from '@reduxjs/toolkit';
import _ from 'lodash';

const PATIENTS_SLICE = 'PATIENTS';

const initialState = {
  patients: [] as Array<any>,
  loadPatients: false as boolean,
};

export const patientsSlice = createSlice({
  name: PATIENTS_SLICE,
  initialState,
  reducers: {
    setPatients: (state, action) => {
      state.patients = action.payload;
    },
    setLoadPatients: (state, action) => {
      state.loadPatients = action.payload;
    },
    sortPatients: (state, action) => {
      let tempList: Array<any> = [];
      if (action.payload.direction === 1) {
        tempList = _.sortBy(state.patients, action.payload.param).reverse();
      } else {
        tempList = _.sortBy(state.patients, action.payload.param);
      }
      state.patients = tempList;
    },
    fetchPatients: () => {},
  },
});

export const {
  setPatients,
  fetchPatients,
  setLoadPatients,
  sortPatients,
} = patientsSlice.actions;

export const patientsSliceSelector = (state: any) => state.patients;

export default patientsSlice.reducer;
