/* eslint-disable indent */
/* eslint-disable max-len */
/* eslint-disable import/prefer-default-export */
import { useEffect, useState } from 'react';
import 'moment/locale/de';
import classNames from 'classnames/bind';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import type {
  Appointment as AppointmentTypes,
  Medication as MedicationType,
  PreparationListItem as PreparationListItemType,
} from '../../../types';
import { Button, Loader } from '../../../components/atoms';
import { LineChart } from '../../../components/organisms/LineChart/LineChart';
import style from './Overview.module.scss';
import { Dialog } from '../../../components/atoms/Dialog/Dialog';
import { declinePatient } from '../patientOverviewSlice';

type PropsTypes = {
  patientInfo: any;
  currentMedication: PreparationListItemType[];
  showMedication(): boolean;
  mainDiseaseLabels: string;
  prevMedication: MedicationType[];
};

const cx = classNames.bind(style);
const GreenHeading = cx('GreenHeading');
const pageClasses = cx('container', { Patient: true });
const TextLabel = cx('TextLabel');
const TextBold = cx('TextBold');
const GreenText = cx('GreenText');

export function Overview({
  patientInfo,
  currentMedication,
  showMedication,
  mainDiseaseLabels,
  prevMedication,
}: PropsTypes): JSX.Element {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const [lastVSS, setLastVSS] = useState<number | null>(0);
  const [nextAppointment, setNextAppointment] = useState<AppointmentTypes[number] | undefined>(undefined);
  const [showDialog, setShowDialog] = useState(false);
  const isPatientDeclined = patientInfo?.isPatientDeclined !== undefined && patientInfo?.isPatientDeclined === true;

  const navigate = useNavigate();

  const getLastVSS = (d: AppointmentTypes) => {
    if (!d) {
      setLastVSS(null);

      return;
    }
    const date = d;

    const now = new Date();
    const lastAppointmentDate = date.filter((dates) => new Date(dates?.startDate) < now && dates.type === 1);
    if (lastAppointmentDate.length === 0) {
      setLastVSS(null);

      return;
    }

    const date1 = new Date(lastAppointmentDate[lastAppointmentDate.length - 1]?.startDate);

    const oneDay = 1000 * 60 * 60 * 24;
    const diffInTime = now.getTime() - date1.getTime();

    const diffInDays: number = Math.round(diffInTime / oneDay);
    setLastVSS(diffInDays);
  };

  const getAppointmentType = (type: number | undefined) => {
    switch (type) {
      case 1:
        return 'Erstgespräch';
      case 2:
        return 'Wiedervorstellung';
      case 3:
        return 'Beratung';
      default:
        return '';
    }
  };

  const getNextAppointment = (appointments: AppointmentTypes) => {
    if (!appointments) {
      setLoading(false);

      return;
    }

    const lastAppointment = appointments[appointments.length - 1];
    if (lastAppointment) {
      if (new Date(lastAppointment.endDate) > new Date()) {
        setNextAppointment(lastAppointment);
      }
    }

    setLoading(false);
  };

  useEffect(() => {
    getLastVSS(patientInfo.appointment);
    getNextAppointment(patientInfo.appointment);
  }, [patientInfo.appointment]);

  const renderCurrentMedication = () => (
    currentMedication.map((element: PreparationListItemType) => (
      <div className={style.MedicineContainer} key={element.id}>
        <p className={style.MedicineText}>{element.name}</p>
        {element.dosageUnit === 'g' && (
          <div className={style.MedicineValue}>
            <p className={TextLabel}>{`${element.quantity} ${element.dosageUnit}`}</p>
          </div>
        )}
      </div>
    )));

  const renderPreviousMedication = () => (
    prevMedication
      .map((d: MedicationType) => (
        <div className={style.MedicationContainer} key={d.id}>
          {d?.preparationListItem?.map((e: PreparationListItemType) => (
            <div className={style.MedicineContainer} key={e.preparationId}>
              <p className={style.MedicineText}>{e.name}</p>
              {e.dosageUnit === 'g' && (
                <div className={style.MedicineValue}>
                  <p className={TextLabel}>{`${e.quantity} ${e.dosageUnit}`}</p>
                </div>
              )}
            </div>
          ))}
        </div>
      ))
      .slice(0, 2));

  const openDeclinePatientDialog = () => setShowDialog(true);

  const declineThisPatient = async () => {
    const payload: any = { patientId: patientInfo.id };
    dispatch(declinePatient(payload));
    setShowDialog(false);
  };

  return (
    <div className={pageClasses}>
      {(loading || Object.keys(patientInfo).length === 0) ? <Loader />
        : <>
          <div className={style.MainContainer}>
            <div className={style.FirstContainer}>
              <div className={style.NameAppointmentContainer}>
                <div className={style.Flex5}>
                  <h1 className={GreenHeading}>ALLGEMEIN</h1>
                  <div className={style.GeneralInfoContainer}>
                    <div className={style.NameDiseaseContainer}>
                      <div className={style.PatientName}>
                        <p className={TextLabel}>Patient</p>
                        <p className={TextBold}>
                          {patientInfo?.firstname} {patientInfo?.lastname},{' '}
                          {patientInfo?.birthday
                            && new Date(patientInfo?.birthday)
                              .toLocaleString('de-DE', {
                                day: '2-digit',
                                month: '2-digit',
                                year: 'numeric',
                              })
                              .replace('-', '.')}
                        </p>
                      </div>
                      <div>
                        <p className={TextLabel}>Hauptdiagnose</p>
                        <p className={TextBold}>{mainDiseaseLabels}</p>
                      </div>
                    </div>
                    <div className={style.RowContainer}>
                      <div className={style.MarginRight70}>
                        <p className={TextLabel}>Status</p>
                        {patientInfo?.active ? <p className={GreenText}>aktiv</p> : <p className={TextBold}>inaktiv</p>}
                      </div>
                      <div className={style.MarginRight50}>
                        <p className={TextLabel}>Tracking</p>
                        {patientInfo?.isPatientTracking ? (
                          <p className={GreenText}>aktiv</p>
                        ) : (
                          <p className={TextBold}>inaktiv</p>
                        )}
                      </div>
                      <div>
                        <p className={TextLabel}>Folgerezept</p>
                        {
                          // eslint-disable-next-line no-constant-condition
                          false ? <p className={GreenText}>aktiv</p> : <p className={TextBold}>inaktiv</p>
                        }
                      </div>
                    </div>
                  </div>
                </div>
                <div className={style.Flex2}>
                  <h1 className={GreenHeading}>TERMINE</h1>
                  <div className={style.AppointmentContainer}>
                    <div className={style.RowContainer}>
                      <div className={style.ColumnContainer}>
                        <p className={TextLabel}>Erstgespräch</p>
                        <p className={TextLabel}>Letzte VSS</p>
                        <p className={TextLabel}>nächster Termin</p>
                      </div>
                      <div className={style.ColumnContainer}>
                        <p className={style.AppointmentText}>
                          {patientInfo?.appointment
                            && patientInfo?.appointment[0]?.startDate
                            && new Date(patientInfo?.appointment[0]?.startDate)
                              .toLocaleString('de-DE', {
                                day: '2-digit',
                                month: '2-digit',
                                year: 'numeric',
                              })
                              .replace('-', '.')}
                        </p>
                        {lastVSS && lastVSS > 20 && <p className={style.RedAppointmentText}>{`vor ${lastVSS}d`}</p>}
                        {lastVSS && lastVSS > 0 && lastVSS <= 20 && (
                          <p className={style.AppointmentText}>{`vor ${lastVSS}d`}</p>
                        )}
                        {!lastVSS && <p className={style.AppointmentText}> </p>}
                        {nextAppointment ? (
                          <p className={style.NextAppointmentText}>
                            {new Date(nextAppointment.startDate)
                              .toLocaleString('de-DE', {
                                day: '2-digit',
                                month: '2-digit',
                                year: '2-digit',
                              })
                              .replace('-', '.')}
                            <br />
                            {new Date(nextAppointment.endDate)
                              .toLocaleString('de-DE', {
                                hour: '2-digit',
                                minute: '2-digit',
                              })
                              .replace('-', '.')}{' '}
                            {' Uhr'}
                            <p className={TextBold}>{getAppointmentType(nextAppointment.type)}</p>
                            {nextAppointment.type === 1
                              ? <p className={TextBold}>
                                  Online
                                  <br />
                                  <Button type="primary" onClick={() => navigate(`../appointment/${nextAppointment.id}`)}>Zur Videosprechstunde</Button>
                                </p>
                              : <p className={TextBold}>Offline</p>}
                          </p>
                        ) : (
                          ''
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <h1 className={GreenHeading}>TRACKING LEBENSQUALITÄT</h1>
                <div className={style.ChartContainer}>
                  <div>
                    <LineChart props={patientInfo?.lifeQuality} />
                  </div>
                </div>
              </div>
            </div>
            {showMedication() && (
              <div className={style.SecondContainer}>
                {currentMedication.length !== 0 && (
                  <div>
                    <h1 className={GreenHeading}>AKTUELLE MEDIKATION</h1>
                    <div className={style.MedicationContainer}>
                      {renderCurrentMedication()}
                    </div>
                  </div>
                )}

                {prevMedication.length !== 0 && (
                  <div>
                    <h1 className={GreenHeading}>VORHERIGE MEDIKATIONEN</h1>
                    {renderPreviousMedication()}
                  </div>
                )}
              </div>
            )}
          </div>
          <div className={style.DeclinePatientButtonContainer}>
            <button disabled={isPatientDeclined} className={isPatientDeclined ? style.DeclinePatientButtonDisabled : style.DeclinePatientButton} onClick={openDeclinePatientDialog}>Therapie des Patienten beenden</button>
          </div>
        </>}
      {showDialog && (
        <Dialog
          messageHead={'Therapie des Patienten beenden'}
          message={'Möchten Sie wirklich die Therapie des Patienten beenden? Der Patient kann im Anschluss keine Termine mehr buchen.'}
          type='negativeDialog'
          buttonText='Ja'
          negativeButtonText={'Abbrechen'}
          onConfirm={() => {
            declineThisPatient();
          }}
          onCancel={() => setShowDialog(false)}
        />
      )}
    </div>
  );
}
