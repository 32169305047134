/* eslint-disable max-len */
import classNames from 'classnames/bind';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { NavigationItem } from '../components/molecules/NavigationItem/NavigationItem';
import style from './MainComponent.module.scss';
import '../styles/styles.scss';
import {
  globalSliceSelector, setNextNavigation, setRenderPatientOverview, setShowNavigationDialog, setLanguage, setRenderPage,
} from '../app/appSlice';
import { setPatientInfo, setDoctorsComments, setDocuments } from './patientOverview/patientOverviewSlice';
import { Icon } from '../components/atoms';
import { authSliceSelector } from './auth/authSlice';

export default function SideBarComponent() {
  const { auth } = useSelector(authSliceSelector);
  const dispatch = useDispatch();
  const {
    isMainOrSubDoctor, hasSavedTask, renderPage, language,
  } = useSelector(globalSliceSelector);

  const languageOptions = [
    { key: 'en', value: 'en', text: 'English' },
    { key: 'de', value: 'de', text: 'Deutsch' },
  ];
  const cx = classNames.bind(style);
  const itemsClasses = cx({ NavigationDrawerItems: true });
  const spacerClasses = cx({ NavigationDrawerSpacer: true });
  const LogoutContainer = cx({ NavigationDrawerLogoutContainer: true });
  const navigate = useNavigate();

  const clearState = () => {
    // reset state for selected patient when changing page
    dispatch(setRenderPatientOverview(-1));
    dispatch(setPatientInfo({}));
    dispatch(setDoctorsComments([]));
    dispatch(setDocuments([]));
    dispatch(setDocuments([]));
  };

  const handleItemClick = (name: string) => {
    if (!isMainOrSubDoctor && hasSavedTask) {
      dispatch(setNextNavigation(name));
      dispatch(setShowNavigationDialog(true));
    } else if (name === 'dashboard') {
      clearState();
      navigate('/dashboard');
      dispatch(setRenderPage(name));
    } else if (name === 'profile') {
      clearState();
      navigate('/profile');
      dispatch(setRenderPage(name));
    } else if (name === 'patients') {
      clearState();
      navigate('/patients');
      dispatch(setRenderPage(name));
    } else if (name === 'calendar') {
      clearState();
      navigate('/calendar');
      dispatch(setRenderPage(name));
    } else if (name === 'prescriptions') {
      clearState();
      navigate('/prescriptions');
      dispatch(setRenderPage(name));
    }
  };

  useEffect(() => {
    dispatch(setNextNavigation(''));
  }, []);

  const onLogout = () => {
    localStorage.removeItem('jwt');
    localStorage.removeItem('ninoxId');

    window.location.reload();
  };

  const handleLanguage = (e: any) => {
    const { innerText } = e.target;
    if (innerText === 'English') {
      dispatch(setLanguage('en'));
    } else if (innerText === 'Deutsch') {
      dispatch(setLanguage('de'));
    }
  };

  return (
    <>
      {auth && (
        <div>
          <div className="navigation_container">
            <div>
              <Icon icon={'logo'} size={200} />
              {/* <Menu.Item>
          <Select placeholder='Select your language' value={language} onChange={(e) => handleLanguage(e)} options={languageOptions} style={{ marginTop: 15 }} />
        </Menu.Item> */}
              <div className={itemsClasses}>
                <NavigationItem icon={'grid'} active={renderPage === 'dashboard'} onClick={() => handleItemClick('dashboard')}>
                  <FormattedMessage id='sideMenu.DashboardLink' />
                </NavigationItem>
                <div className={spacerClasses} />
                <NavigationItem icon={'doctor'} active={renderPage === 'profile'} onClick={() => handleItemClick('profile')}>
                  <FormattedMessage id='sideMenu.profileLink' />
                </NavigationItem>
                <NavigationItem icon={'account-plus'} active={renderPage === 'patients'} onClick={() => handleItemClick('patients')} >
                  <FormattedMessage id='sideMenu.PatientsLink' />
                </NavigationItem>
                <NavigationItem icon={'calendar'} active={renderPage === 'calendar'} onClick={() => handleItemClick('calendar')}>
                  <FormattedMessage id='sideMenu.Calendar' />
                </NavigationItem>
                <NavigationItem icon={'checklist'} active={renderPage === 'prescriptions'} onClick={() => handleItemClick('prescriptions')}>
                  <FormattedMessage id='sideMenu.Prescriptions' />
                </NavigationItem>
                <div
                  className={LogoutContainer}
                  onClick={() => onLogout()}
                >
                  <NavigationItem icon={'logout'} active={false}>
                    <FormattedMessage id='sideMenu.LogoutLink' />
                  </NavigationItem>
                </div>
              </div>
            </div>
          </div>
          {/* <div className="details_container">
      {renderContent()}
    </div> */}
        </div>
      )}
    </>
  );
}
