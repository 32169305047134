import { useEffect, useRef } from 'react';
import type { LocalVideoTrack, RemoteVideoTrack } from 'twilio-video';
import { useMediaStreamTrack } from './hooks/useMediaStreamTrack';
import { useVideoTrackDimensions } from './hooks/useVideoTrackDimensions';
import classes from './TwilioRoom.module.scss';

export function TwilioVideoTrack({ track, local }: { track: LocalVideoTrack | RemoteVideoTrack; local?: boolean }) {
  const ref = useRef<HTMLVideoElement>(null!);
  const mediaStreamTrack = useMediaStreamTrack(track);
  const dimensions = useVideoTrackDimensions(track);

  // Attach the track to a <video> element.
  useEffect(() => {
    const el = ref.current;
    el.muted = true;
    track.attach(el);

    return () => {
      track.detach(el);

      // This addresses a Chrome issue where the number of WebMediaPlayers is limited.
      // See: https://github.com/twilio/twilio-video.js/issues/1528
      el.srcObject = null;
    };
  }, [track]);

  // Work out the full className.
  const isPortrait = (dimensions?.height ?? 0) > (dimensions?.width ?? 0);
  const isMirror = local && mediaStreamTrack?.getSettings().facingMode !== 'environment';
  const className = `${classes['twilio-video']} ${isMirror ? classes['twilio-video-mirror'] : ''} ${isPortrait ? classes['twilio-video-portrait'] : classes['twilio-video-landscape']}`;

  return <video ref={ref} className={className} />;
}
