/* eslint-disable import/prefer-default-export */
import React, { useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import classNames from 'classnames/bind';
import style from './ResetPassword.module.scss';
import { Input } from '../../../components/molecules';
import { Button, Icon } from '../../../components/atoms';
import { resetPassword } from '../../../utils/apiCalls';

const cx = classNames.bind(style);

export function ResetPassword(): JSX.Element {
  const navigate = useNavigate();
  const token = useSearchParams()[0].get('token') || '';

  const [password, setPassword] = useState('');
  const [repeatedPassword, setRepeatedPassword] = useState('');
  const [error, setError] = useState({
    passwordReq: false,
    passwordEqual: false,
    emailTaken: false,
  });

  const validate = () => {
    const regex = /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])/;
    const newError = {
      passwordReq: !regex.test(password) || password.length < 8,
      passwordEqual: password !== repeatedPassword,
    };
    setError({
      ...error,
      ...newError,
    });

    return newError;
  };

  const onResetPasswordClick = async () => {
    const newError = validate();
    if (Object.values(newError).find((e) => e)) {
      return;
    }

    const resp = await resetPassword({
      token,
      password,
    });
    if (!resp.error) {
      navigate('/login');
    }
  };

  const ResetPasswordPageClasses = cx({ ResetPassword: true });
  const ResetPasswordBoxClasses = cx({ ResetPasswordBox: true });
  const LogoContainerClasses = cx('margin-10');
  const InputClasses = cx('margin-10');
  const ButtonClasses = cx('margin-10');

  return (
    <div className={ResetPasswordPageClasses}>
      <div className={ResetPasswordBoxClasses}>
        <div className={LogoContainerClasses}>
          <Icon icon='logo' size={160} />
        </div>
        <Input
          onChange={(event) => setPassword(event.target.value)}
          className={InputClasses}
          type='password'
          icon='magnify'
          placeholder='Passwort...'
          size={15}
        />
        <Input
          onChange={(event) => setRepeatedPassword(event.target.value)}
          className={InputClasses}
          type='password'
          icon='magnify'
          placeholder='Passwort wiederholen...'
          size={15}
        />
        <Button className={ButtonClasses} type='primary' onClick={onResetPasswordClick}>
          Passwort setzen
        </Button>
      </div>
    </div>
  );
}
