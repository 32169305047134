/* eslint-disable import/prefer-default-export */
import classNames from 'classnames/bind';
import style from './Radio.module.scss';

const cx = classNames.bind(style);

interface RadioItem {
  key: string;
  label: string;
}

interface RadioProps {
  name: string;
  items: RadioItem[];
  layout: 'horizontal' | 'vertical';
  onChange: (checkedKey: string) => void;
  value: string;
}

const Wrapper = cx({ RadioWrapper: true });
const RadioInput = cx({ RadioInput: true });
const Delegate = cx({ RadioDelegate: true });
const Indicator = cx({ RadioIndicator: true });

export const Radio = ({
  name, items, layout, onChange, value,
}: RadioProps) => {
  const refs: any = [];

  const toggleCheck = (key: string): void => {
    const ref = refs.find((refItem: any) => refItem.key === key);
    ref.ref.dispatchEvent(new MouseEvent('click'));
    onChange(key);
  };

  return (
    <div style={{ display: 'flex', flexDirection: layout === 'vertical' ? 'column' : 'row', gap: 20 }}>
      {items.map(({ key, label }: RadioItem) => (
        <div className={Wrapper} key={key}>
          <input
            id={key}
            type='radio'
            name={name}
            value={key}
            className={RadioInput}
            checked={value === key}
            ref={(ref) => {
              const exists = refs.find((refItem: any) => refItem.key === key);
              if (!exists) {
                refs.push({ key, ref });
              }
            }}
          />
          <div onClick={() => toggleCheck(key)} className={Delegate}>
            <div className={Indicator} />
          </div>
          <label onClick={() => toggleCheck(key)} htmlFor={key}>{label}</label>
        </div>
      ))}
    </div>
  );
};
