/* eslint-disable import/prefer-default-export */
const preparationObject = {
  preparationId: '',
  quantity: '',
  dosageUnit: '',
  dosageMax: '',
  dosageSingle: '',
  type: -1,
  manufacturer: '',
  name: '',
  cultivar: '',
  form: -1,
  thc: '',
  cbd: '',
};

export const initialPatientInformation = {
  active: false,
  address: [],
  availableFollowUpPrescription: 0,
  birthday: '',
  comment: '',
  email: '',
  firstname: '',
  id: -1,
  insurance: '',
  isPatientTracking: false,
  language: '',
  lastname: '',
  nationality: '',
  sex: '',
  cannabisTherapy: {
    hasImprovedCondition: false,
    hasMedicalCannabisTreatment: false,
    hasPensionApplication: false,
    hasRecommendationCannabisTherapy: false,
    hasReductionGainfulEmployment: false,
    hasServereDisability: false,
    hasSupport: false,
    id: -1,
    isLongTermSickness: false,
    patientId: -1,
    preparation: '',
    triedThcCannabis: false,
    comment: '',
  },
  complaints: [],
  lastAppointment: '',
  firstPresentation: {
    id: '',
    mainDisease: '',
    designation: '',
    sinceDisease: '',
    previousDoctors: '',
    previousDoctorsOther: '',
    hasMainDiagnosis: false,
    relatives: false,
    frequentComplaints: '',
    isUploadDocuments: false,
    patientId: '',
  },
  secondaryDiagnosis: {
    cancerous: '',
    cancerousDisease: false,
    cardiovascularDiseases: '',
    cardiovascularDiseasesOther: '',
    dermatology: '',
    dermatologyOther: '',
    gastrointestinalDiseases: '',
    gastrointestinalDiseasesOther: '',
    gynecologicalAnamnesis: '',
    gynecologicalAnamnesisOther: '',
    hasOtherDiseases: false,
    id: -1,
    infectiousDiseases: '',
    infectiousDiseasesOther: '',
    movementDiseases: '',
    movementDiseasesOther: '',
    neurologicalDiseases: '',
    neurologicalDiseasesOther: '',
    patientId: -1,
    psychiatricDiseases: '',
    psychiatricDiseasesOther: '',
    respiratoryDiseases: '',
    respiratoryDiseasesOther: '',
  },
  lifeQuality: [],
  appointment: [],
  medication: [],
  followUpPrescriptionQuestion: {
    generalSideEffects: '',
    id: -1,
    isEnough: false,
    isSafeHandling: false,
    isSamePharmacy: false,
    medicationId: -1,
    newPharmacy: '',
    patientId: -1,
    preparationQuestion: [
      {
        timeOfIngestion: [-1],
        id: 0,
        followUpPrescriptionQuestionId: 0,
        preparationListItemId: 0,
        dailyDosageMax: '',
        singleDosageMax: '',
        used: -1,
      },
    ],
    psychologicalSideEffects: '',
    psychologicalSideEffectsOther: '',
    newMedicines: '',
    medicalNews: '',
    roadTraffic: -1,
    generalSideEffectsOther: '',
    exchangePreparation: '',
    followUpPrescriptionQuestionId: -1,
    preparationListItemId: preparationObject,
    dailyDosageMax: preparationObject,
    singleDosageMax: preparationObject,
    medicationAvailable: 0,
  },
  followUpPrescriptionRequest: {
    id: -1,
    patientId: -1,
    requestDate: '',
  },
};
