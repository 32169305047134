import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import type { Patient } from '../../types';
import type { RootState } from '../store';
import { initialPatientInformation } from './initialPatientInformation';

const initialState: Patient = initialPatientInformation;

export const patientSlice = createSlice({
  name: 'patient',
  initialState,
  reducers: {
    setPatientInformation: (state, action: PayloadAction<Patient>) =>
      /* eslint-disable */
       action.payload
      /* eslint-enable */
    ,
  },
});

export const { setPatientInformation } = patientSlice.actions;
export const selectPatientInformation = (state: RootState) => state.patient;
