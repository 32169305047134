import classNames from 'classnames/bind';
import style from './Tracking.module.scss';

const cx = classNames.bind(style);
const pageClasses = cx('container', { Patient: true });

export default function Tracking(): JSX.Element {
  return (
    <div className={pageClasses}>
      <h1>Tracking</h1>
    </div>
  );
}
