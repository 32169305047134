/* eslint-disable import/prefer-default-export */
import { IconDefaults } from '../IconDefaults';
import type { IconProps } from '../IconProps';

export function Grid({ size = IconDefaults.size, color = IconDefaults.color }: IconProps) {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 17.54 18.28' width={size} height={size}>
      <g id='a' />
      <g id='b'>
        <g id='c'>
          <g>
            <path
              fill={color}
              d='M16.41,0h-5.59c-.63,0-1.13,.51-1.13,1.13V6.73c0,.62,.51,1.13,1.13,1.13h5.59c.62,0,1.13-.51,1.13-1.13V1.13c0-.63-.51-1.13-1.13-1.13Zm.54,6.73c0,.3-.24,.54-.54,.54h-5.59c-.3,0-.54-.24-.54-.54V1.13c0-.3,.24-.54,.54-.54h5.59c.3,0,.54,.24,.54,.54V6.73Z'
            />
            <path
              fill={color}
              d='M6.73,10.42H1.13c-.63,0-1.13,.51-1.13,1.13v5.59c0,.62,.51,1.13,1.13,1.13H6.73c.62,0,1.13-.51,1.13-1.13v-5.59c0-.62-.51-1.13-1.13-1.13Zm.54,6.73c0,.3-.24,.54-.54,.54H1.13c-.3,0-.54-.24-.54-.54v-5.59c0-.3,.24-.54,.54-.54H6.73c.3,0,.54,.24,.54,.54v5.59Z'
            />
            <path
              fill={color}
              d='M16.41,10.42h-5.59c-.63,0-1.13,.51-1.13,1.13v5.59c0,.62,.51,1.13,1.13,1.13h5.59c.62,0,1.13-.51,1.13-1.13v-5.59c0-.62-.51-1.13-1.13-1.13Zm.54,6.73c0,.3-.24,.54-.54,.54h-5.59c-.3,0-.54-.24-.54-.54v-5.59c0-.3,.24-.54,.54-.54h5.59c.3,0,.54,.24,.54,.54v5.59Z'
            />
            <path
              fill={color}
              d='M6.73,0H1.13C.51,0,0,.51,0,1.13V6.73C0,7.35,.51,7.86,1.13,7.86H6.73c.62,0,1.13-.51,1.13-1.13V1.13c0-.63-.51-1.13-1.13-1.13Zm.54,6.73c0,.3-.24,.54-.54,.54H1.13c-.3,0-.54-.24-.54-.54V1.13c0-.3,.24-.54,.54-.54H6.73c.3,0,.54,.24,.54,.54V6.73Z'
            />
          </g>
        </g>
      </g>
    </svg>
  );
}
