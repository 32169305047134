import { createSlice } from '@reduxjs/toolkit';

const PRESCRIPTIONS_SLICE = 'PRESCRIPTIONS';

const initialState = {
  isDisplayPrescriptionPage: false,
};

export const prescriptionsSlice = createSlice({
  name: PRESCRIPTIONS_SLICE,
  initialState,
  reducers: {
    setIsDisplayPrescriptionInfo: (state, action) => {
      // eslint-disable-next-line no-param-reassign
      state.isDisplayPrescriptionPage = action.payload;
    },
  },
});

export const {
  setIsDisplayPrescriptionInfo,
} = prescriptionsSlice.actions;

export const prescriptionsSliceSelector = (state: any) => state.prescriptions;

export default prescriptionsSlice.reducer;
