/* eslint-disable import/prefer-default-export */
import classNames from 'classnames/bind';
import { Button } from '../Button/Button';
import style from './Dialog.module.scss';

const cx = classNames.bind(style);

type DialogProps = {
  spinner?: 'none' | 'primary' | 'secondary' | 'red-primary';
  messageHead?: string;
  message: string;
  onCancel: any;
  onConfirm: any;
  disabled?: boolean;
  type: string;
  buttonText: string;
  negativeButtonText?: string;
};

export function Dialog({
  spinner = 'none',
  messageHead,
  message,
  onCancel,
  onConfirm,
  disabled = false,
  type,
  buttonText,
  negativeButtonText = 'Abbrechen',
}: DialogProps): JSX.Element {
  const pageClasses = cx('container');
  const Container = cx({ DialogContainer: true });
  const InnerContainer = cx({ DialogInnerContainer: true });
  const Heading = cx({ DialogHeading: true });
  const ButtonsContainer = cx({ DialogButtonsContainer: true });
  const ConfirmButton = cx({ DialogConfirmButton: true });
  const ConfirmButtonDisabled = cx({ DialogConfirmButtonDisabled: true });
  const CancelButton = cx({ DialogCancelButton: true });
  const NegativeButton = cx({ DialogNegativeButton: true });

  return (
    <div className={pageClasses}>
      <div className={Container}>
        <div className={InnerContainer}>
          {messageHead && <h2>{messageHead}</h2>}
          <h6 className={Heading}>{message}</h6>
          <div className={ButtonsContainer}>
            <Button children={negativeButtonText} className={CancelButton} type={'secondary'} onClick={onCancel} />
            {type === 'positiveDialog' ? (
              <Button
                children={buttonText}
                className={disabled ? ConfirmButtonDisabled : ConfirmButton}
                type={'primary'}
                spinner={spinner}
                onClick={onConfirm}
                disabled={disabled}
              />
            ) : (
              <Button
                children={buttonText}
                className={NegativeButton}
                type={'primary'}
                onClick={onConfirm}
                disabled={disabled}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
