/* eslint-disable import/prefer-default-export */
import React, { useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';
import classNames from 'classnames/bind';
import { useSelector } from 'react-redux';
import { FormattedMessage, useIntl } from 'react-intl';
import { useAppDispatch } from '../../store/hooks';
import { Button, Icon } from '../../components/atoms';
import { Input } from '../../components/molecules';
import style from './Login.module.scss';
import { login, authSliceSelector, setAuthError } from './authSlice';
import { PasswordForgot } from './passwordForgot/PasswordForgot';

const cx = classNames.bind(style);

export function Login(): JSX.Element {
  const dispatch = useAppDispatch();
  const state = useSelector(authSliceSelector);
  const { authError } = state;
  const intl = useIntl();

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [renderForgotPassword, setRenderForgotPassword] = useState(false);

  useEffect(() => {
    if (state.auth) {
      <Navigate to='/main' />;
    }
  }, [state.auth]);

  useEffect(() => {
    if (authError) {
      dispatch(setAuthError(null));
    }
  }, [dispatch, authError]);

  const handleSubmit = () => {
    const user: any = {
      username: email.toLowerCase(),
      password,
    };
    dispatch(login(user));
  };

  const LoginPageClasses = cx({ Login: true });
  const LoginBoxClasses = cx({ LoginBox: true });
  const LogoContainerClasses = cx('margin-10');
  const InputClasses = cx('margin-10');
  const ButtonClasses = cx('margin-10');

  return (
    renderForgotPassword ? <PasswordForgot /> : (<div className={LoginPageClasses}>
      <div className={LoginBoxClasses}>
        <div className={LogoContainerClasses}>
          <Icon icon="logo" size={160} />
        </div>
        <Input
          onChange={(event) => setEmail(event.target.value)}
          className={InputClasses}
          type="text"
          icon="magnify"
          placeholder="Username..."
          size={15}
        />
        <Input
          onChange={(event) => setPassword(event.target.value)}
          className={InputClasses}
          type="password"
          icon="magnify"
          placeholder={intl.formatMessage({ id: 'login.Password' })}
          size={15}
        />
        <Button className={ButtonClasses} type="primary" onClick={handleSubmit}>
        Login
        </Button>
        <Button type="secondary" onClick={() => setRenderForgotPassword(!renderForgotPassword)}>
          <FormattedMessage id='login.ForgotPassword' />
        </Button>
      </div>
    </div>)

  );
}
