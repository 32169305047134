/* eslint-disable import/prefer-default-export */
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import classNames from 'classnames/bind';
import style from './PasswordForgot.module.scss';
import { Button, Icon } from '../../../components/atoms';
import { Input } from '../../../components/molecules';
import { forgotPassword } from '../../../utils/apiCalls';

const cx = classNames.bind(style);

export function PasswordForgot(): JSX.Element {
  const navigate = useNavigate();
  const [email, setEmail] = useState('');

  const handleSubmit = async () => {
    const forgotData = {
      email,
    };

    const response = await forgotPassword(forgotData);

    if (!response.error) {
      setEmail('');
      setTimeout(() => {
        navigate('/login');
      }, 1000);
    }
    if (response.error) {
      if (response.message.response?.data?.error) {
        console.log('Mail');
      }
      console.log(response.message);
    }
  };

  const PasswordForgotPageClasses = cx({ PasswordForgot: true });
  const PasswordForgotBoxClasses = cx({ PasswordForgotBox: true });
  const LogoContainerClasses = cx('margin-10');
  const InputClasses = cx('margin-10');
  const ButtonClasses = cx('margin-10');

  return (
    <div className={PasswordForgotPageClasses}>
      <div className={PasswordForgotBoxClasses}>
        <div className={LogoContainerClasses}>
          <Icon icon='logo' size={160} />
        </div>
        <Input
          onChange={(event) => setEmail(event.target.value)}
          className={InputClasses}
          type='text'
          icon='magnify'
          placeholder='E-Mail...'
          size={15}
        />
        <Button className={ButtonClasses} type='primary' onClick={handleSubmit}>
          Passwort zurücksetzen
        </Button>
      </div>
    </div>
  );
}
