/* eslint-disable import/prefer-default-export */
/* eslint-disable import/no-unresolved */
/* eslint-disable import/extensions */
import axios from 'axios';
import type { AxiosReturn } from '../types/return.types';
import { API_URL } from '../utils/apiUrl';
import { getAPIToken } from '../utils/apiToken';

axios.defaults.baseURL = API_URL;
axios.defaults.headers.common['Content-Type'] = 'application/json';

export class DoctorService {
  static async getPrescriptionTasks(): Promise<AxiosReturn> {
    axios.defaults.headers.common.Authorization = `Bearer ${getAPIToken()}`;
    return new Promise((resolve) => {
      axios
        .get('/doctor/prescription-tasks')
        .then((response) => {
          resolve({ error: false, message: 'Success', data: response.data });
        })
        .catch((error) => {
          resolve({ error: true, message: 'Error', data: error });
        });
    });
  }

  static async sendPrescriptions(formData: FormData): Promise<AxiosReturn> {
    return new Promise((resolve) => {
      axios
        .post('/doctor/send-prescription', formData, { headers: { 'Content-Type': 'multipart/form-data' } })
        .then((response) => {
          resolve({ error: false, message: 'Success', data: response.data });
        })
        .catch((error) => {
          resolve({ error: true, message: 'Error', data: error });
        });
    });
  }
}
